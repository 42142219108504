<template>
    <v-main>
        <div
            class="payments-container"
        >
            <v-col style="padding: 0 !important;">
                <SuppliersCarousel v-if="$UserManager.getMainRole() == 'customer'"/>
            </v-col>
            <v-row>
                <v-col class="col-12">
                    <v-alert
                        v-if="$UserManager.getMainRole() == 'customer'"
                        type="info"
                        color="#1d1d1f"
                        block
                        outlined
                    >
                        Con el botón <b>ver acciones</b> podrás: pagar, solicitar extensión de plazo al proveedor y ver la factura.
                    </v-alert>
                    <v-alert
                        v-if="$UserManager.getMainRole() == 'supplier'"
                        type="info"
                        color="#1d1d1f"
                        block
                        outlined
                    >
                    Con el botón <b>ver acciones</b> podrás: informar un pago; extender, rechazar y confirmar extensiones de plazo; reportar impagos; adjuntar y ver facturas.
                    </v-alert>
                </v-col>
            </v-row>
            <v-row class="mt-6">
                <v-col cols="12">
                    <v-row class="mb-4">
                        <v-col cols="12" class="d-flex justify-space-between">
                            <v-chip-group
                                v-model="filter.status"
                                mandatory
                                active-class="white--text black"
                            >
                                    <v-chip
                                        v-for="st in status"
                                        :key="st.code"
                                        :value="st.code"
                                        medium
                                    >
                                        {{ st.name }}
                                    </v-chip>
                                </v-chip-group>
    
                                <div class="d-flex">
                                    <v-btn
                                        outlined
                                        small
                                        @click="showReportModal = true"
                                        color="#1d1d1f"
                                        class="mr-2"
                                        v-if="isRoleAdmin"
                                    >
                                        <v-icon left>mdi-file-chart</v-icon>
                                        Reporte de clientes
                                    </v-btn>
                                    <v-btn
                                        outlined
                                        small
                                        @click="showExportModal = true"
                                        color="#1d1d1f"
                                        class="mr-2"
                                        v-if="isRoleAdmin"
                                    >
                                        <v-icon left>mdi-file-excel</v-icon>
                                        Exportar
                                    </v-btn>

                                    <v-btn
                                        outlined
                                        small
                                        @click="showFilter = !showFilter"
                                        color="#1d1d1f"
                                        class="btn-filter mr-2"
                                    >
                                        <v-icon left>mdi-filter</v-icon>
                                        {{ !showFilter ? 'Filtrar' : 'Ocultar filtro' }}
                                    </v-btn>                                 
                                </div>
                        </v-col>
                    </v-row>
                    <v-row class="mb-4 btn-filter-mobile">
                        <v-col cols="12">
                            <v-btn
                                outlined
                                small
                                @click="showFilter = !showFilter"
                                color="deep-purple"
                            
                            >
                                <v-icon left>mdi-filter</v-icon>
                                {{ !showFilter ? 'Filtrar' : 'Ocultar filtro' }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                          <v-col cols="12" md="2">
                            <div class="d-flex align-start">
                              <v-text-field
                                  v-model="filterId"
                                  label="Filtrar por ID"
                                  dense
                                  outlined
                              ></v-text-field>
                              <v-btn
                                class="mx-2 mt-1"
                                fab
                                x-small
                                color="#1d1d1f"
                                style="color: #fff;"
                                @click="getById()"
                              >
                              <v-icon dark>
                                mdi-magnify
                              </v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-if="showFilter">
                        <v-col cols="12" md="2">
                                <v-select
                                v-model="filter.counterpart_id"
                                :items="counterpart"
                                :label="getCounterpartLabel"
                                item-text="name"
                                item-value="id"
                                dense
                                outlined
                            ></v-select>    
                        </v-col>
                        <v-col cols="12" md="2" v-if="reservedCreditLines.length > 0">
                                <v-select
                                v-model="filter.reserved_credit_line_id"
                                :items="reservedCreditLines"
                                label="Líneas reservadas"
                                item-text="name"
                                item-value="id"
                                dense
                                outlined
                            ></v-select>    
                        </v-col>
                        <v-col
                            cols="12" md="2">
                            <v-menu
                                ref="menuStart"
                                v-model="menuStart"
                                :close-on-content-click="false"
                                :return-value.sync="filter.start"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filter.start"
                                            label="Fecha inicio"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            dense
                                            outlined
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="filter.start"
                                    no-title
                                    scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="menuStart = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menuStart.save(filter.start)"
                                        >
                                            OK
                                        </v-btn>    
                                    </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                            cols="12" md="2">
                            <v-menu
                                ref="menuEnd"
                                v-model="menuEnd"
                                :close-on-content-click="false"
                                :return-value.sync="filter.end"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="filter.end"
                                            label="Fecha fin"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            dense
                                            outlined
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="filter.end"
                                    no-title
                                    scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="menuEnd = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menuEnd.save(filter.end)"
                                        >
                                            OK
                                        </v-btn>    
                                    </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="4" class="mb-4">
                            <v-btn 
                                medium 
                                outlined 
                                @click="getPayments()"
                                class="black white--text"
                            >
                                Aplicar
                            </v-btn>
                            <v-btn 
                                medium 
                                outlined 
                                @click="resetFilter()" 
                                class="ml-2 black white--text"
                            >
                                Reestablecer
                            </v-btn> 
                        </v-col>
                    </v-row>
                    <!-- <div class="text-h5 text-center mb-4">{{ ["admin-supplier", "vendor-supplier"].includes($UserManager.getRole()) ? "Ventas" : "Compras" }}</div> -->
                    <TableComponent 
                        :items="payments" 
                        :headers="getHeaders()" 
                        :code="$route.params.status" 
                        :loading="loading" 
                        :page="page"
                        @handleAction="handleActionPayment" 
                        @updatePaymentsOrderBy="updatePaymentsOrderBy"              
                        @assign-project="handleAssignProject" 
                    />
                </v-col>
                <div class="col col-12 text-center">
                    <v-pagination
                    v-model="page"
                    :length="totalPages"
                    :total-visible="6"
                    circle
                    color="black" 
                    active-class="black"
                    ></v-pagination>
                </div>
            </v-row>
        </div>
        <DialogComponent :visible="dialog" @close="handleCloseDialog" :title="dialogContent.title" :text="dialogContent.text" :text_button="dialogContent.text_button" @handleAction="dialogContent.action"/>
        <DialogAlert :visible="dialogAlert" :message="dialogAlertContent" @handleCloseDialogAlert="handleCloseDialogAlert"/>
        <DialogExtendTerm :payment="item" :visible="showDialogExtendTerm" @handleCloseDialogAlert="handleCloseDialogAlert" @close="showDialogExtendTerm = false"  @showDialogAlert="showDialogAlert" @refreshData="getPayments"/>
        <ReportPaymentDialog :visible="reportPaymentDialog" :item="item" @close="item = null; reportPaymentDialog = false" @showDialogAlert="showDialogAlert" @refreshData="getPayments"/>
        <AttachInvoice :visible="showAttachInvoice" :item="item" @close="showAttachInvoice = false" @success="(data) => { showDialogAlert(data); getPayments() }"/>
        <DialogEditPayment :payment="item" :visible="showDialogEditPayment" @handleCloseDialogAlert="handleCloseDialogAlert" @close="showDialogEditPayment = false"  @showDialogAlert="showDialogAlert" @refreshData="getPayments"/>
        <DialogRequestExtendTerm :payment="item" :visible="showDialogRequestExtendTerm" @handleCloseDialogAlert="handleCloseDialogAlert" @close="showDialogRequestExtendTerm = false"  @showDialogAlert="showDialogAlert" @refreshData="getPayments"/>
        <DialogExportPayments :visible="showExportModal" @close="showExportModal = false" @showDialogAlert="showDialogAlert" />
        <DialogReportPayments :visible="showReportModal" @close="showReportModal = false" @showDialogAlert="showDialogAlert" />
        <DialogProjectAssignment v-if="selectedTransaction && selectedTransaction.id" v-model="showProjectDialog" @confirm="handleProjectAssignment" :transaction-id="selectedTransaction.id" :title="selectedTransaction?.project_name ? 'Editar Proyecto' : 'Asignar Proyecto'"/>
    </v-main>
</template>

<script>
import PaymentService from "../services/paymentService"
import translate_code from "../helpers/translate_code"
import { getHeadersPaymentsAll } from "../helpers/table"
import TableComponent from "../components/TableComponent.vue"
import UserManager from '@/services/manager/UserManager'
import CustomerService from '@/services/customerService'
import SupplierService from '@/services/supplierService'
import ProjectService from "@/services/ProjectService"
import getDialog from "../helpers/dialogs"
import getPaymentData from "../helpers/payment_data"
import DialogComponent from "../components/DialogComponent"
import DialogAlert from "../components/DialogAlert"
import DialogExtendTerm from '@/components/DialogExtendTerm.vue'
import ReportPaymentDialog from "../components/ReportPaymentDialog"
import AttachInvoice from "../components/AttachInvoice"
import DialogEditPayment from "../components/DialogEditPayment"
import ReservedCreditLineService from '../services/reservedCreditLineService'
import DialogRequestExtendTerm from "../components/DialogRequestExtendTerm"
// import FileService from "@/services/fileService"
import DialogExportPayments from "@/components/DialogExportPayments.vue";
import DialogReportPayments from "@/components/DialogReportPayments.vue";
import DialogProjectAssignment from "@/components/DialogProjectAssignment.vue";
import SuppliersCarousel from "@/components/SuppliersCarousel.vue"


export default {

     components: {
        TableComponent,
        DialogComponent,
        DialogAlert,
        DialogExtendTerm,
        ReportPaymentDialog,
        AttachInvoice,
        DialogEditPayment,
        DialogRequestExtendTerm,
        DialogExportPayments,
        DialogReportPayments,
        DialogProjectAssignment,
        SuppliersCarousel
    },

    data() {
        return {
            payments: [],
            payment_codes: {},
            page: 1,
            totalPages: null,
            loading: false,
            status: [ 
                {name: "Pendientes", code: "pending"},
                {name: "A confirmar", code: "to_confirm"},
                {name: "Pagadas", code: "paid"},
                {name: "Vencidas", code: "expired"},
                {name: "Por vencer", code: "about_to_expire"}
            ],   
            counterpart: [],
            reservedCreditLines: [],
            menuStart: false,   
            // start: null,
            menuEnd: false,
            // end: null,
            filter: {
                status: null,
                counterpart_id: null,
                start: null,
                end: null,
                reserved_credit_line_id: null,
                order_by: "expiration_date",
                order_by_condition: "asc"
            },
            filterId: null,
            dialogContent: {
                title: "",
                text: "",
                text_button: "",
                action: () => {}
            },
            dialog: false,
            dialogAlert: false,
            dialogAlertContent: {
                title: "",
                success: "",
                text: ""
            },
            reportPaymentDialog: false,
            item: null, //only use in reportPayment
            showDialog: false,
            showDialogExtendTerm: false,
            showDialogFile: false,
            showAttachInvoice: false,
            showDialogEditPayment: false,   
            showDialogRequestExtendTerm: false,
            showFilter: false,
            showExportModal: false,
            showReportModal: false,
            showProjectDialog: false,
            selectedTransaction: null,
        }
    },

    async created() {

        // this.filter.status = this.$route.params.status ?? "pending"
        const mainRole = UserManager.getMainRole()

        if (mainRole == "customer") {
            this.filter.status = this.$route.params.status ?? "expired"
        } else {
            this.filter.status = this.$route.params.status ?? "about_to_expire"
        }
      
        this.filter.reserved_credit_line_id = this.$route.params.reserved_credit_line_id
        
        this.payment_codes = getPaymentData()

        await this.getPayments()

        await this.getCounterpart();

        if (mainRole == "supplier") {
            await this.getReservedCreditLinesBySupplier();
        }
    },

    methods: {

      async getById() {
          this.loading = true

          const response = await PaymentService.get({id: this.filterId})
          const status = response.status
          const result = await response.json()
          
          if (status != 200) {
              console.log("Ocurrio un error")
              return
          }

          this.payments = result.data
          this.totalPages = result.last_page
          this.loading = false
          this.filterId = null
      },
        
        async getPayments() {
            this.loading = true

            const params = this.getParams()
            params.page = this.page

            console.log({params})

            const response = await PaymentService.get(params)
            const status = response.status
            const result = await response.json()
            
            if (status != 200) {
                console.log("Ocurrio un error")
                return
            }

            this.payments = result.data
            this.totalPages = result.last_page
            this.loading = false
        },
        
        getParams() {
            let params = {}

            if (this.filter.status == "expired") {
                params.status_group = "expired"
            }

            params["paginate"] = 10

            for(let prop in this.filter) {
                if (this.filter[prop] && prop !== 'id') {
                    params[prop] = this.filter[prop]
                }
            }

            if (params["status_group"] != null) {
                delete params["status"] 
            }

            return params
        },

        async getCounterpart() {
            let response = null

            if (UserManager.getMainRole() == "supplier") {
                response = await SupplierService.getCustomers()
            } else {
                response = await CustomerService.getSuppliers()
            }

            const status = response.status

            if (status != 200) {
                console.log("Ocurrio un error")
            }   

            this.counterpart = await response.json()
        },

        async getReservedCreditLinesBySupplier () {
            let response = null

            response = await ReservedCreditLineService.getBySupplier(0)
            
            const status = response.status

            if (status != 200) {
                console.log("Ocurrio un error")
            }

            this.reservedCreditLines = await response.json()
        },

        async resetFilter() {
            this.filter = {
                status: UserManager.getMainRole() == "customer" ? "expired" : "to_confirm",
                counterpart_id: null,
                start: null,
                end: null
            }

            this.showFilter = false

            await this.getPayments()
        },

        handleActionPayment (item, code) {
            if (code == "pending") {
                this.item = item
                this.reportPaymentDialog = true
                return
            }

            if (code == "extend_term") { // To extend term
                this.item = item
                this.showDialogExtendTerm = true
                return
            }

            if (code == "show_file") {
                this.item = item
                window.open(this.item.proof_payment_url);
                return
            }

            if (code == "attach_invoice") { // To extend term
                this.item = item
                this.showAttachInvoice = true
                return
            }

            if (code == "edit_payment") {
                this.item = item
                this.showDialogEditPayment = true
                return
            }

            if (code == "request_extend_term") {
                this.item = item
                this.showDialogRequestExtendTerm = true
                return
            }

            if (code == "show_invoice") {
                this.item = item
                this.showInvoice();
            }

            this.setDialogData(code)
            const action = this.payment_codes[code].handleAction
            this.dialogContent.action = () => this[action](item)
        },

        setDialogData (code) {
            const dialogTexts = getDialog(code)
            if (dialogTexts) {
                this.dialogContent.title = dialogTexts.title
                this.dialogContent.text = dialogTexts.text
                this.dialogContent.text_button = dialogTexts.text_button
                this.dialog = true  
            }
        },

        handleCloseDialog () {
            this.dialog = false  
            this.dialogContent.title = ""
            this.dialogContent.text = ""
            this.dialogContent.text_button = ""
            this.action = () => {}
        },

        handleCloseDialogAlert () {
            this.dialogAlert = false
            this.dialogAlertContent.title = ""
            this.dialogAlertContent.success = ""
            this.dialogAlertContent.text = ""
        },

        showDialogAlert(data) {
            console.log(data.success, data.msg)
            this.dialogAlertContent.title = ""
            this.dialogAlertContent.success = data.success
            this.dialogAlertContent.text = data.msg
            this.dialogAlert = true
            console.log(this.dialogAlertContent)
        },

        translate_code (key) {
          return translate_code(key)
        },

        getHeaders () {
            return getHeadersPaymentsAll("supplier")
        },

         //Suppliers
        async confirmPayment (item) {
            const response = await PaymentService.confirmPayment(item.id)
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error al confirmar", result)
            }

            this.handleCloseDialog()

            this.showDialogAlert(result)

            this.getPayments()
        },

        async reportNonPayment (item) {
            const response = await PaymentService.reportNonPayment(item.id)
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error al confirmar", result)
            }

            this.handleCloseDialog()

            this.showDialogAlert(result)

            this.getPayments()
        },

         async reportPayment (item) {
            const response = await PaymentService.reportPayment(item.id)
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error al confirmar", result)
            }

            this.handleCloseDialog()

            this.showDialogAlert(result)

            this.getPayments()
        },

        async showInvoice() {
            window.open(this.item.invoice_url);
        },

        updatePaymentsOrderBy (options) {
            if (options.sortDesc[0] !== undefined && options.sortBy[0] !== undefined) {
                this.filter.order_by_condition = options.sortDesc[0] ? "desc" : "asc"
                this.filter.order_by = options.sortBy[0]
            }
        },
        openProjectAssignmentDialog(item) {
            this.selectedTransaction = item;
            this.showProjectDialog = true;
        },

        async handleProjectAssignment(project) {
            if (this.selectedTransaction) {
                const payload = {
                    payment_id: this.selectedTransaction.id,
                    project_id: project.id,
                    customer_id: this.selectedTransaction.customer_id
                };

                const response = await PaymentService.assignProjectToPayment(payload);
                const result = await response.json();

                if (response.ok && result.success) {
                    // console.log('Proyecto asignado/editado correctamente:', result.message);

                    // Actualiza el proyecto en el frontend
                    this.selectedTransaction.project_name = project.name;
                    this.showProjectDialog = false; // Cierra el modal
                } else {
                    // console.error('Error al asignar/editar el proyecto:', result.message);
                }
            }
        },

        async handleAssignProject(item) {
            // Establece la transacción seleccionada
            this.selectedTransaction = item;

            // Carga los proyectos antes de abrir el modal
            await this.fetchProjects();

            // Abre el modal solo si los proyectos se cargan correctamente
            this.showProjectDialog = true;
        },

        async fetchProjects() {
            try {
                const response = await ProjectService.getProjects();
                const projects = await response.json();
            this.projects = projects;
            } catch (error) {
                console.error('Error al cargar proyectos:', error);
            }
        },



    },
    computed: {
        getCounterpartLabel () {
            return UserManager.getMainRole() == "supplier" ? "Cliente" : "Proveedor"
        },
        
        isRoleAdmin() {
            // Obtener role_id desde localStorage
            const user = JSON.parse(localStorage.getItem('user'));
            return user && user.role_id === 2;
        },
    },
    watch: {
        async page () {
            await this.getPayments(this.$route.params.status)
        },

        async 'filter.status'() {
            await this.getPayments()
        },

        async 'filter.order_by'() {
            await this.getPayments()
        },
        async 'filter.order_by_condition'() {
            await this.getPayments()
        }
    }
}
</script>

<style>
    .payments-container {
        padding: 4em;
    }

    .btn-filter-mobile {
        display: none;
    }

    @media(max-width: 998px) {
        .payments-container {
            padding: 0.5em;
        }

        .btn-filter {
            display: none;
        }

        .btn-filter-mobile {
            display: flex;
        }
    }

    .black--text {
        background: #fff !important;
        color: #000 !important;
    }
</style>