<template>
    <v-container class="text-center">
        <div class="text-h4 font-weight-medium mb-4">¡Has finalizado tu solicitud!</div>
        <v-icon
            style="font-size: 80px !important; color: #fec70f;"
            class="white"
        >
            mdi mdi-check-circle
        </v-icon>
        <div style="font-size: 1.2em;" class="mt-4">Te avisaremos al correo registrado cuando hayamos finalizado con la evaluación</div>
    </v-container>
</template>

<script>
    export default {
        props: ["email"],
        created() {
            this.$emit("saveStep", "register_completed")
            localStorage.removeItem("register_token")
        }
    }
</script>