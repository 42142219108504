<template>
    <v-card style="background-color: #000; text-align: center; padding: 2px;">           
        <div
            style="color: #fff; font-weight: 500;">
            ¿Necesitas más crédito?
        </div>
        <div class="d-flex justify-center align-center">
                <v-switch  
                    elevation="0"
                    v-model="extendCreditLine"
                    @change="handleExtendCreditLine"
                    flat
                    color="deep-purple"
                ></v-switch>
            <div  style="color: #fff; font-weight: 500;">Si</div>
        </div>
    </v-card>
</template>
<script>    

    import CustomerService from "@/services/customerService"

    export default {

        name: "CreditExtensionToggle",

        data() {
            return {}
        },

        methods: {
            async handleExtendCreditLine (e) {
                
                if (!e) {
                    return
                }

                const response = await CustomerService.creditExtension()
                const result = await response.json()

                this.$emit("showDialogAlert", result)
            }

            // async showCreditExtensionForm (e) {
            //     alert(e)
            //     if (e) {
            //         this.$emit("showCreditExtensionForm")
            //     }
            // }   
        }
    }
</script>