<template>
    <v-main style="background-color: #edecec;">
        <SuppliersCarousel v-if="$UserManager.getMainRole() == 'customer'"/>

        <v-container
            class="py-8 px-6"
            style="background-color: #edecec; "
            fluid
        >
        <v-container>
            <MenuActionPayments /> 
            <v-row>
                <v-col
                cols="12"
                >
                <v-row no-gutters>
                    <div class="text-h3 font-weight-bold mt-5 mb-5" style="color:#1d1d1f;">{{ $UserManager.get().business_name }}</div>
                    <v-spacer></v-spacer>
                </v-row>
                </v-col>
            </v-row>
            <v-row class="pt-6 pb-6" v-if="$UserManager.getMainRole() == 'customer'">
                <v-col cols="12">
                    <div class="text-h3 font-weight-bold primary--text">
                        {{  availableAmount | currency }}
                    </div>
                    <div class="text-h5 font-weight-bold primary--text">
                        Disponible
                    </div>
                </v-col>
                <v-col cols="12" md="3"  v-if="$UserManager.getMainRole() == 'customer'">
                    <CreditExtensionToggle 
                        @showDialogAlert="showDialogAlert"
                    />
                </v-col>
            </v-row>
            
            <v-row class="pt-6 pb-6" v-if="$UserManager.getMainRole() == 'supplier' && $UserManager.get().has_rewards == true">
                <v-col cols="12">
                    <div class="text-h5 font-weight-bold deep-purple--text">
                        Mis ventas del mes
                    </div>
                    <div class="text-h3 font-weight-bold deep-purple--text">
                        {{ totalPayments }}
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="0"
                    md="12"
                    lg="3"
                    v-for="(value, key, index) in resume"
                    :key="key"
                    >
                    <v-card color="#1d1d1f" elevation="0">
                        <v-card-title style="color: #fafafa; font-weight: 400;">
                            {{ translate_code(key) }}
                        </v-card-title>
                        <v-card-text>
                            <v-row align="center">
                                <v-col
                                cols="12"
                                >
                                <div class="text-h4 font-weight-medium" style="color: #fafafa;" v-if="index % 2 != 0">
                                {{  value | currency }}
                                </div>
                                <div class="text-h4 font-weight-medium" style="color: #fafafa;" v-else>
                                {{  value }}
                                </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- v-if="$UserManager.getRole() == 'vendor-supplier'" -->
        <!-- <div class="container-fluid">
        <v-row  class="mt-6">
            <v-col cols="12">
                <div class="text-h4 text-center mb-4 font-weight-medium">Solicitudes de extensión de plazos</div>
                <ExtendTermTable />
            </v-col>
        </v-row>
        <v-row v-for="(value, key, index) in payments" :key="key" class="mt-6">
            <v-col cols="12">
                <div class="text-h4 text-center mb-5 mt-5 font-weight-medium">Transacciones {{ translate_code(key).toLowerCase() }}</div>
                <TableComponent :key="index" :items="value.data" :headers="getHeaders()" :code="key" :loading="value.loading" @handleAction="handleActionPayment"/>
                <div class="text-center mt-6">
                    <v-btn
                        align="center"
                        outlined
                        color="#1d1d1f"
                        @click="$router.push({ name: 'payments', params: {status: key} })"
                        block
                        >
                        Ver mas
                    </v-btn>
                </div>
            </v-col>
       </v-row> -->
    <!-- </div> -->
        <DialogComponent :visible="dialog" @close="handleCloseDialog" :title="dialogContent.title" :text="dialogContent.text" :text_button="dialogContent.text_button" @handleAction="dialogContent.action"/>
        <ReportPaymentDialog :visible="reportPaymentDialog" :item="item" @close="item = null; reportPaymentDialog = false" @showDialogAlert="showDialogAlert" @refreshData="init"/>
        <DialogAlert :visible="dialogAlert" :message="dialogAlertContent" @handleCloseDialogAlert="handleCloseDialogAlert"/>
        <DialogExtendTerm :payment="item" :visible="showDialogExtendTerm" @handleCloseDialogAlert="handleCloseDialogAlert" @close="showDialogExtendTerm = false"  @showDialogAlert="showDialogAlert" @refreshData="init"/>
        <!-- DialogFile use to show proof payment-->
        <DialogFile v-if="item && item.proof_payment_url != undefined" :visible="showDialogFile" :src="item.proof_payment_url" @close="showDialogFile = false"/>
        <DialogRequestExtendTerm :payment="item" :visible="showDialogRequestExtendTerm" @handleCloseDialogAlert="handleCloseDialogAlert" @close="showDialogRequestExtendTerm = false"  @showDialogAlert="showDialogAlert" @refreshData="init"/>
    </v-container>

    <v-container fluid style="background: #ffffff; border-radius: 5em;">
        <v-container>
            <v-row class="mt-6">
                <v-col cols="12">
                    <div class="text-h4 text-center mb-4 font-weight-medium">Solicitudes de extensión de plazos</div>
                    <ExtendTermTable />
                </v-col>
            </v-row>
            <v-row v-for="(value, key, index) in payments" :key="key" class="mt-6">
                <v-col cols="12">
                    <div class="text-h4 text-center mb-5 mt-5 font-weight-medium">Transacciones {{ translate_code(key).toLowerCase() }}</div>
                    <TableComponent :key="index" :items="value.data" :headers="getHeaders()" :code="key" :loading="value.loading" @handleAction="handleActionPayment"/>
                    <div class="text-center mt-6">
                        <v-btn
                            align="center"
                            outlined
                            color="#1d1d1f"
                            @click="$router.push({ name: 'payments', params: {status: key} })"
                            block
                            >
                            Ver mas
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
    </v-main>
</template>

<script>
import PaymentService from "../services/paymentService"
import translate_code from "../helpers/translate_code"
import TableComponent from "../components/TableComponent.vue"
import DialogComponent from "../components/DialogComponent"
import ReportPaymentDialog from "../components/ReportPaymentDialog"
import DialogAlert from "../components/DialogAlert"
import getHeadersPaymentsBase from "../helpers/table"
import getPaymentData from "../helpers/payment_data"
import getDialog from "../helpers/dialogs"
import DialogExtendTerm from '@/components/DialogExtendTerm.vue'
import DialogFile from "../components/DialogFile"
import DialogRequestExtendTerm from "../components/DialogRequestExtendTerm"
import ExtendTermTable from "../components/ExtendTermTable.vue"
import CreditExtensionToggle from '@/components/creditExtension/Toggle.vue'
import SuppliersCarousel from "@/components/SuppliersCarousel.vue"
import MenuActionPayments from "@/components/MenuActionPayments"

export default {

    components: {
        TableComponent,
        DialogComponent,
        ReportPaymentDialog,
        DialogAlert,
        DialogExtendTerm,
        DialogFile,
        DialogRequestExtendTerm,
        ExtendTermTable,
        CreditExtensionToggle,
        SuppliersCarousel,
        MenuActionPayments
    },

    data: () => ({
        resume: {},
        availableAmount: 0,
        payments: {},
        dialogContent: {
            title: "",
            text: "",
            text_button: "",
            action: () => {}
        },
        dialog: false,
        dialogAlert: false,
        dialogAlertContent: {
            title: "",
            success: "",
            text: ""
        },
        reportPaymentDialog: false,
        item: null, //only use in reportPayment
        showDialog: false,
        showDialogExtendTerm: false,
        showDialogFile: false,
        showDialogRequestExtendTerm: false,
        totalPayments: "-"
    }),

    created() {
        this.payments = getPaymentData()
        // this.showTutorial()

    },

    async mounted() {
       await this.init()
    },

    methods: {

        async init () {
            await this.getResume()
            await this.getPayments("to_confirm")
            await this.getPayments("expired")
            await this.getPayments("pending")

            if (this.$UserManager.getMainRole() == 'supplier') {
                await this.getTotalPaymentsByUser()
            }
        },

        async getResume () {
            const response = await PaymentService.getResume()
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error");
                return
            }

            this.availableAmount = result.amount_available
            delete result.amount_available
            this.resume = result
        },

        async getPayments (status_code) {

            this.payments[status_code].loading = true

            const response = await PaymentService.get({status: status_code, paginate: 3})
            const status = response.status
            const result = await response.json()
            
            if (status != 200) {
                console.log("Ocurrio un error")
                return
            }

            this.payments[status_code].data = result.data
            this.payments[status_code].loading = false
        },

        async getTotalPaymentsByUser () {
            const response = await PaymentService.getTotalPaymentsByUser()
            const status = response.status
            const result = await response.json()
            
            if (status == 200) {
                this.totalPayments = result.total
            }
        },

        handleActionPayment (item, code) {
  
            if (code == "pending") {
                this.item = item
                this.reportPaymentDialog = true
                return
            }

            if (code == "extend_term") { // To extend term
                this.item = item
                this.showDialogExtendTerm = true
                return
            }

            if (code == "show_file") {
                this.item = item
                this.showDialogFile = true
                return
            }

            if (code == "request_extend_term") {
                this.item = item
                this.showDialogRequestExtendTerm = true
                return
            }

            if (code == "reject") {
                this.item = item
                this.dialogContent.title = "Rechazar pago"
                this.dialogContent.text = "Se notificara al cliente que el pago no se ha realizado"
                this.dialogContent.text_button = "Confirmar"
                this.dialog = true  
                this.dialogContent.action = () => this.rejectPayment(item)
                return
            }

            if (code == "show_invoice") {
                this.item = item
                this.showInvoice();
            }

            this.setDialogData(code)
            const action = this.payments[code].handleAction
            this.dialogContent.action = () => this[action](item)
        },

        setDialogData (code) {
            const dialogTexts = getDialog(code)
            if (dialogTexts) {
                this.dialogContent.title = dialogTexts.title
                this.dialogContent.text = dialogTexts.text
                this.dialogContent.text_button = dialogTexts.text_button
                this.dialog = true  
            }
        },

        handleCloseDialog () {
            this.dialog = false  
            this.dialogContent.title = ""
            this.dialogContent.text = ""
            this.dialogContent.text_button = ""
            this.action = () => {}
        },

        handleCloseDialogAlert () {
            this.dialogAlert = false
            this.dialogAlertContent.title = ""
            this.dialogAlertContent.success = ""
            this.dialogAlertContent.text = ""
        },

        showDialogAlert(data) {
            console.log(data.success, data.msg)
            this.dialogAlertContent.title = ""
            this.dialogAlertContent.success = data.success
            this.dialogAlertContent.text = data.msg
            this.dialogAlert = true
            console.log(this.dialogAlertContent)
        },

        translate_code (key) {
            return translate_code(key)
        },

        getHeaders () {
            return getHeadersPaymentsBase()
        },

        //Suppliers
        async confirmPayment (item) {
            const response = await PaymentService.confirmPayment(item.id)
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error al confirmar", result)
            }

            this.handleCloseDialog()

            this.showDialogAlert(result)

            this.init()
        },

        async reportNonPayment (item) {
            const response = await PaymentService.reportNonPayment(item.id)
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error al confirmar", result)
            }

            this.handleCloseDialog()

            this.showDialogAlert(result)

            this.init()
        },

         async reportPayment (item) {
            const response = await PaymentService.reportPayment(item.id)
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error al confirmar", result)
            }

            this.handleCloseDialog()

            this.showDialogAlert(result)

            this.init()
        },

        async rejectPayment(item) {
            const response = await PaymentService.rejectPayment(item.id)
            const status = response.status
            const result = await response.json()

            if (status != 200) {
                console.log("Ocurrio un error al rechazar el pago", result)
            }

            this.handleCloseDialog()

            this.showDialogAlert(result)

            this.init()
        },

        showTutorial() {
            if (this.$UserManager.getMainRole() == "supplier") {
                return
            }

            const buyTutorialShowed = localStorage.getItem("buyTutorialShowed")

            if (localStorage.getItem("buyTutorialShowed") === null) {
                localStorage.setItem("buyTutorialShowed", true)
                this.showDialog = true
            }

            if (buyTutorialShowed == true) {
                this.showDialog = false
            }
        },

        async showInvoice() {
            window.open(this.item.invoice_url);
        }
    },

    filters: {
        currency(value) {
            
            var formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
            
            return formatter.format(value);
        }
    }
}
</script>

<style>
.v-messages {
    display: none !important;
}

.v-input--selection-controls {
    margin-top: 0 !important;
    padding-left: 5px;
    padding-right: 5px;
}
</style>