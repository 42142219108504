<template>
  <v-main>
    <v-container class="py-8 px-6">
      <v-row>
        <v-col>
          <SuppliersCarousel v-if="$UserManager.getMainRole() == 'customer'"/>
        </v-col>
        <v-col cols="12">
          <v-alert 
          outlined 
          color="#1d1d1f" 
          type="info">
            Aumenta tu linéa de crédito <b>asignando</b> tus <b>compras</b> a <b>proyectos.</b>
          </v-alert>
        </v-col>
      </v-row>
      <v-col cols="12">
        <v-row
          class="d-flex align-center"
        >
          <div class="text-h4 font-weight-bold pb-6 pt-6" style="color: #1d1d1f;">Mis proyectos</div>
          <v-spacer></v-spacer>
          <v-btn 
            :small="$vuetify.breakpoint.smAndUp"
            :x-small="$vuetify.breakpoint.xsOnly"
            outlined
            color="#1d1d1f"
            @click="showEditModal = true"
          >+ Nuevo proyecto</v-btn>
        </v-row>
      </v-col>
      <v-row v-if="projects.length > 0">
        <v-col cols="12">
          Vincúla tus gastos a proyectos al realizar una <a @click="$router.push({ name: 'token' })" style="color: #1d1d1f; text-decoration: underline;"><b>nueva compra</b></a> o desde el 
          <a @click="$router.push({ name: 'payments' })" style="color: #1d1d1f; text-decoration: underline;"><b>listado de tus compras.</b></a>
        </v-col>
        <v-col cols="12" md="6" lg="12" v-for="project in projects" :key="project.id">
          <ProjectCard :project="project" @editProject="openEditModal" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          No tienes proyectos, crea uno ahora haciendo clic en el botón <b>Nuevo proyecto.</b>
          <ProjectCard class="mt-5" :project="exampleProject"/>
        </v-col>
      </v-row>

      <DialogProjectEdit
        :key="dialogKey"
        :visible="showEditModal"
        :project="selectedProject"
        @close="closeEditModal"
        @projectUpdated="updateProject"
      />
    </v-container>
  </v-main>
</template>

<script>
import ProjectService from "@/services/ProjectService";
import ProjectCard from "@/components/ProjectCard.vue";
import DialogProjectEdit from "@/components/DialogProjectEdit.vue";
import SuppliersCarousel from "@/components/SuppliersCarousel.vue"

export default {
  name: "ProjectsView",
  components: {
    ProjectCard,
    DialogProjectEdit,
    SuppliersCarousel
  },
  
  data() {
    return {
      projects: [],
      showEditModal: false,
      selectedProject: {}, 
      dialogKey: 0,
      exampleProject: {
        name: "Proyecto de ejemplo",
        budget: 1500000,
        address: "Mangxo 2025",
        percentage_used: 108,
        total_amount: 1620000
      } 
    };
  },

  async created() {
    await this.fetchProjects();
  },

  methods: {
    async fetchProjects() {
      try {
        const response = await ProjectService.getProjectsWithTotals();
        if (response.ok) {
          const projects = await response.json();
          this.projects = projects;
        } else {
          console.error("Error al obtener proyectos:", response.status);
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
      }
    },

    openEditModal(project) {
      this.selectedProject = { ...project }; // Clonar objeto
      this.showEditModal = true;
      this.dialogKey += 1; // Incrementa clave para forzar re-render
    },

    closeEditModal() {
      this.showEditModal = false;
      this.selectedProject = {}; // Restablece objeto vacío
    },

    updateProject(updatedProject) {
      const index = this.projects.findIndex(p => p.id === updatedProject.id);
      if (index !== -1) { 
        this.$set(this.projects, index, updatedProject) 
      } else {
        this.projects.unshift(updatedProject);
      }
    },
  },
};
</script>
