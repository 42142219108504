<template>
    <v-dialog v-model="show" max-width="500px">
        <v-card>
            <v-card-title class="mb-5 text-h4 font-weight-medium">
                {{ title }}
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
    
            <v-card-text>
                <v-overlay :value="loading" absolute>
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-overlay>
        
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                        v-model="name"
                        label="Nombre del proyecto"
                        placeholder="Nombre del proyecto"
                        :rules="[v => !!v || 'El nombre del proyecto es requerido']"
                        outlined
                    ></v-text-field>
                    <vuetify-money
                        v-model="budget"
                        label="Presupuesto"
                        placeholder="Presupuesto"
                        valueWhenIsEmpty=""
                        v-bind:options="options"
                        outlined
                    />  
                    <v-text-field
                        v-model="address"
                        label="Dirección"
                        placeholder="Dirección"
                        outlined
                    ></v-text-field>
                </v-form>
        
                <div v-if="message" class="red--text mt-2">{{ message }}</div>

                <div class="d-flex">
                    <v-col cols="6">
                        <v-btn block outlined class="" @click="close" :disabled="loading">Cancelar</v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn block outlined class="black white--text" :disabled="!valid || loading" @click="handleSubmit">
                            Guardar
                        </v-btn>
                    </v-col>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
  import ProjectService from "@/services/ProjectService";
  import options from '@/config/money'
  
    export default {
        props: {
        visible: { type: Boolean, required: true },
        project: { type: Object, default: () => ({ id: null, name: null, budget: null, address: null }) },
        },
    
        data() {
            return {
                show: this.visible,
                name: this.project.name,
                budget: this.project.budget || 0,
                // status: this.project.status || "",
                address: this.project.address || "",
                statuses: ["Activo", "Inactivo", "En progreso", "Completado"],
                valid: true,
                loading: false
            };
        },
    
        methods: {

            async handleSubmit () {

                const payload = {
                    name: this.name,
                    budget: this.budget,
                    address: this.address,
                };

                this.loading = true;

                try {
                    let response = null

                    if (this.newProject) {
                        response = await ProjectService.createProject(payload);
                    } else {
                        response = await ProjectService.updateProject(this.project.id, payload);
                    }

                    if (response.ok) {
                        const result = await response.json();
                        this.$emit("projectUpdated", result.project);
                        this.close();
                    }

                } catch (error) {
                    console.error("Error al actualizar el proyecto:", error);
                } finally {
                    this.loading = false;
                }
            },
        
            close() {
                this.resetForm();
                this.$emit("close");
            },
        
            resetForm() {
                this.name = null
                this.budget = null
                this.address = null
                this.message = null
            },
        },
    
        watch: {
            visible(value) {
                this.show = value;
                if (value) this.resetForm();
            },
        },

        computed: {
            options: () => {
                return options
            },

            title () {
                return !this.newProject ? "Editar proyecto" : "Nuevo proyecto"
            },

            newProject () {
                return this.project.id ? false : true
            }
        }
    };
</script>
<style scoped>
    .v-card-title {
        font-weight: bold;
    }
</style>