<template>
    <v-main>
        <div class="reserved-container">
            <div class="text-h3 font-weight-bold" style="margin-bottom: 1em;">Líneas reservadas</div>
            <v-alert
                dense
                type="info"
                color="#1d1d1f"
                prominent
                outlined
                class="mt-5 mb-5"
            >
                Sube la factura de la compra en un <b>plazo máximo de 3 días</b> después de su emisión para garantizar la protección.
            </v-alert>
            <v-row class="">
                <v-col cols="12">
                    <v-col cols="12">
                        <v-row>
                            <v-btn 
                                style="background: #1d1d1f ; color: #fff"
                                class="mb-4" 
                                small
                                @click="getReservedCreditLinesBySupplier"
                            >
                                <v-icon>mdi mdi-reload</v-icon>
                                Actualizar
                            </v-btn>
                            <v-spacer></v-spacer>
                            <div class="d-flex">
                                <v-btn 
                                    style="background: #1d1d1f ; color: #fff"
                                    class="mb-4" 
                                    small
                                    @click="showCreateReservedCreditLine = true"
                                >
                                    <v-icon>mdi mdi-plus</v-icon>
                                    Reservar línea
                                </v-btn>
                            </div>
                        </v-row>
                    </v-col>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        class="elevation-1 mt-5"
                        :loading="loading"
                        loading-text="Cargando..."
                        :search="search"
                    >
                        <template v-slot:top>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="search"
                                        label="Buscar cliente"
                                        class="mx-4"
                                        prepend-icon="mdi-magnify"
                                        outlined
                                        small
                                    ></v-text-field>
                                </v-col>
                        </v-row>
                        </template>
                        <template v-slot:[`item.amount`]="{ item }">
                            {{ item.amount | currency }} <br>
                        </template>
                         <template v-slot:[`item.available`]="{ item }">
                            {{ item.available | currency }} <br>
                        </template>
                        <template v-slot:[`item.active`]="{ item }">
                            {{ item.active ? "Sí" : "No" }} <br>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                            x-small
                            style="background: #1d1d1f; color: #fff"
                            @click="handleDeactivateLine(item)"
                            >
                                <v-icon x-small>mdi mdi-close-box-multiple</v-icon>
                                Eliminar
                            </v-btn>
                            <v-btn
                                x-small
                                style="background: #1d1d1f; color: #fff"
                                @click="handleCreatePayment(item)"
                                class="ml-2"
                            >
                            <v-icon x-small>mdi mdi-plus</v-icon>
                            Nueva venta
                            </v-btn>
                             <v-btn
                                x-small
                                class="ml-2"
                                @click="$router.push({ name: 'payments', params: {reserved_credit_line_id: item.id} })"
                            >
                            <v-icon>mdi mdi-eye</v-icon>
                            Ver facturas
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </div>
        <CreateComponent 
            :visible="showCreateReservedCreditLine" 
            @close="showCreateReservedCreditLine = false"
            @showMessage="showMessage"
        />
        <CreatePaymentComponent 
            :selected="selectedToCreatePayment"
            :visible="showCreatePayment" 
            @close="showCreatePayment = false"
            @showMessage="showMessage"
        />
        <DeactivateLineComponent 
            :selected="selectedToCreatePayment"
            :visible="showDeactivateLine" 
            @close="showDeactivateLine = false"
            @showMessage="showMessage"
        />
         <DialogAlert 
            :visible="dialogAlert" 
            :message="dialogAlertContent" 
            @handleCloseDialogAlert="handleCloseDialogAlert"
        />
    </v-main>
</template>

<script>
import CreateComponent from '@/components/reservedCreditLines/CreateComponent.vue'
import CreatePaymentComponent from '@/components/reservedCreditLines/CreatePaymentComponent.vue'
import DeactivateLineComponent from '@/components/reservedCreditLines/DeactivateLineComponent.vue'
import ReservedCreditLineService from '@/services/reservedCreditLineService'
import DialogAlert from "@/components/DialogAlert"


export default {

    components: {
        CreateComponent,
        CreatePaymentComponent,
        DeactivateLineComponent,
        DialogAlert
    },
    
    data() {
        return {
            items: [],
            headers: [
                {"text": "ID", "value": "id"},
                {"text": "Empresa", "value": "business_name"}, 
                {"text": "Monto", "value": "amount"},
                {"text": "Disponible", "value": "available"},
                {"text": "Días", "value": "days"},
                {"text": "Expiración", "value": "expiration_date"},
                {"text": "Creado", "value": "created"},
                {"text": "Activo", "value": "active"},
                {"text": "Acciones", "value": "actions"},
            ],
            loading: false,  
            showCreateReservedCreditLine: false,
            showCreatePayment: false,
            showDeactivateLine: false,
            selectedToCreatePayment: null,
            dialogAlert: false,
            dialogAlertContent: {
                title: "",
                success: "",
                text: ""
            },
            search: null
        }
    },

    async mounted () {
        this.getReservedCreditLinesBySupplier()
    },

    methods: {
        async getReservedCreditLinesBySupplier () {

            this.loading = true

            const response = await ReservedCreditLineService.getBySupplier()
            const result = await response.json()

            this.items = result

            this.loading = false
        },

        handleDeactivateLine (item) {
            this.showDeactivateLine = true
            this.selectedToCreatePayment = item    
        },

        handleCreatePayment (item) {
            this.showCreatePayment = true
            this.selectedToCreatePayment = item
        },

        showMessage(data){ 
            this.dialogAlert = true
            this.dialogAlertContent.title = ""
            this.dialogAlertContent.success = data.success
            this.dialogAlertContent.text = data.msg

            this.getReservedCreditLinesBySupplier()
        },

        handleCloseDialogAlert () {
            this.dialogAlert = false
            this.dialogAlertContent.title = ""
            this.dialogAlertContent.success = ""
            this.dialogAlertContent.text = ""
        },
    },

    filters: {
        currency(value) {
            
            var formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
            
            return formatter.format(value);
        }
    }
    
}
</script>

<style>
.reserved-container {
    padding: 4em;
}
</style>