<template>
<div>
    <v-row class="mt-6">
        <v-col
            lg="8"
            offset="2"
            >
            <v-card>
                <v-card-text>
                    <v-row align="center">
                        <v-col class="text-center">
                            <div class="text-h4 mt-2 mb-4 font-weight-medium" style="color: #1d1d1f;" v-if="result.success">
                                {{result.msg}}
                                <br><br>

                                <v-btn
                                    v-if="showAttachInvoiceButton"
                                    class="lighten-1--text mt-4"
                                    @click='showAttachInvoice = true'
                                    outlined
                                    block
                                >
                                   Adjuntar factura
                                </v-btn>    
                            </div>
                            <div class="text-h4 mt-2 mb-4 red--text font-weight-medium" v-if="!result.success">¡COMPRA RECHAZADA!</div>

                            <div :class="{
                                    'text-h5': result.msg && result.msg.length <= 40, 
                                    'subtitle': result.msg && result.msg.length > 40,
                                    'mt-2': true,
                                    'red--text': true,
                                    'font-weight-medium': true
                                }" 
                            v-if="!result.success && result.msg">{{ result.msg }}</div>
                            
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12 text-center">
                            <div v-if="!result.success">
                                <v-btn
                                    class="black white--text mt-2"
                                    @click='$emit("goBack")'
                                    block
                                >
                                   Editar compra
                                </v-btn>
                            </div>
                            <div class="mt-2" v-if="result.success">
                                <v-btn
                                    class="black white--text"
                                    @click="generateCreditCheck()"
                                    :disabled="isGeneratingPdf"
                                    block
                                >
                                   Imprimir comprobante
                                </v-btn>
                            </div>
                            <div class="mt-2">
                                <span v-if="isGeneratingPdf">Generando comprobante...</span>
                            </div>
                            <div class="mt-2">
                                <v-btn
                                    class="black white--text"
                                    @click="reloadPage"
                                    block
                                >
                                   Volver a inicio
                                </v-btn>
                                 <v-btn
                                    v-if="$UserManager.getRole() == 'vendor-supplier'"
                                    class="black white--text mt-2"
                                    @click='$emit("goBack", true)'
                                    block
                                >
                                   Volver
                                </v-btn>
                            </div>
                            <div class="mt-2">
                            <v-btn
                                class="black white--text"
                                target="_blank"
                                href="https://api.whatsapp.com/send/?phone=528119825427&text=%C2%A1Hola%21+Quiero+hablar+con+un+asesor"
                                block
                            >
                                Contactar un asesor
                            </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <AttachInvoice :visible="showAttachInvoice" :item="result.data" @close="showAttachInvoice = false" @success="dialogAlert = true; showAttachInvoiceButton = false"/>
    <DialogAlert :visible="dialogAlert" :message="dialogAlertContent" @handleCloseDialogAlert="dialogAlert = false"/>
</div>
</template>

<script>
import PaymentService from '../../services/paymentService'
import AttachInvoice from '../AttachInvoice'
import DialogAlert from "../DialogAlert"

export default {
    props: ["result"],

    components: {
        AttachInvoice,
        DialogAlert
    },

    data() {
        return {
            isGeneratingPdf: false,
            showAttachInvoiceButton: true,
            showAttachInvoice: false,
            dialogAlert: false,
            dialogAlertContent: {
                title: "Factura",
                success: true,
                text: "Factura adjunta con exito"
            },
        }
    },

    methods: {
        async generateCreditCheck() {

            this.isGeneratingPdf = true
            const response = await PaymentService.generateCreditCheck(this.result.data.id)

            if (!response) {
                console.log("Ocurrio un error. Exception")
            }

            const status = response.status

            if (status == 500) {
                console.log("Ocurrio uun error")
            }

            if (status == 404) {
                console.log("No se encontro el pago")
            }

            if (status == 200) {

                const file = await response.blob()

                const aElement = document.createElement('a');
                aElement.setAttribute('download', 'credit-check.pdf');
                const href = URL.createObjectURL(file);
                aElement.href = href;
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
            }

            this.isGeneratingPdf = false
        },

        reloadPage() {
            window.location.reload();
        }
    }
}

</script>
