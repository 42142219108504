<template>
    <div class="text-center">
      <v-dialog
          v-model="show"
          max-width="500"
        >
        <v-card>
            <v-card-title class="mb-5 text-h4 font-weight-medium">Liberar línea reservada</v-card-title>
            <v-card-text>
                <v-col cols="12">
                    <div style="font-size: 1.2em;" class="mb-2">Cliente: {{ selected.business_name }}</div>
                    <div style="font-size: 1.2em;" class="mb-2">ID de reserva: {{ selected.id }}</div>
                    <div style="font-size: 1.2em;" class="mb-2">Monto de línea: {{ selected.amount | currency }}</div>
                    <div style="font-size: 1.2em;" class="mb-2"><b>Disponible: {{ selected.available | currency }}</b></div>
                </v-col>
                <div v-if="message" class="red--text">{{message}}</div>
                <div class="d-flex">
                  <v-col cols="6">
                    <v-btn
                      block
                      @click="close"
                      outlined
                    >
                      Cerrar
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      block
                      class="black white--text"
                      @click="handleSubmit"
                      :disabled="loading"
                      outlined
                    >
                    {{ loading ? "Cargando..."  : "Confirmar" }}
                    </v-btn>
                  </v-col>
                </div>
            </v-card-text>
            
            <v-card-actions>
              <v-spacer></v-spacer>
              
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
  </template>
  
  <script>
  import ReservedCreditLineService from '../../services/reservedCreditLineService'
  
  export default {
      props: [
        "visible",
        "selected"
      ],
  
      data() {
          return {
            amount: null,
            term: null,
  
            loading: false,
            message: null,
            valid: false,
          }
      },
  
      methods: {
        
        async handleSubmit () {
         
          this.loading = true
  
          const response = await ReservedCreditLineService.deactivateLine(this.selected.id)
          const result = await response.json()
          const status = response.status
  
          if (status == 200) {
            this.$emit("showMessage", {success: true, msg: result.msg})
            this.close()
          } else {
            this.message = result.msg
          }
  
          this.loading = false
        },
  
        close () {
          this.reset()
          this.$emit('close')
        },
  
        reset() {
          this.amount = null
          this.term = null
          this.customer_id = null
          this.message = null
        }
      },
  
      computed: {
        show: {
          get () {
            return this.visible
          },
          set (value) {
            if (!value) {
              this.close()
            }
          }
        },
      },
  
      filters: {
          currency(value) {
              
              var formatter = new Intl.NumberFormat('es-MX', {
                  style: 'currency',
                  currency: 'MXN'
              });
              
              return formatter.format(value);
          }
      }
  }
  
  </script>