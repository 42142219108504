<template>
    <v-main>
        <v-container
        class="py-8 px-6"
        >
            <v-row class="">
                <v-col cols="12">
                    <div class="text-h3 font-weight-bold" style="margin-bottom: 1em;">Clientes</div>
                    <div class="container">
                        <v-row class="d-flex align-center mb-2">
                            <v-btn 
                                style="background: #1d1d1f; color: #fff"
                                class="mb-4" 
                                @click="getReferralsBySupplier()" 
                                :disabled="loading"
                                small
                            >
                                <v-icon>mdi mdi-reload</v-icon>
                                Actualizar
                            </v-btn>
                              <v-btn class=" mb-4 ml-2" 
                                    style="background: #1d1d1f; color: #fff" 
                                    href="https://app.mangxo.com/register/info"
                                    target="_blank"
                                    small
                                >
                                <v-icon>mdi mdi-account</v-icon>
                                Crear cliente
                            </v-btn>
                            <v-spacer></v-spacer>
                          
                            <v-btn
                                style="background: #1d1d1f; color: #fff"
                                small
                                @click="$router.push({ name: 'reserved-credit-lines' })"
                            >
                            Líneas reservadas
                            </v-btn>
                            <v-chip
                                class="ml-2"
                                style="background: #1d1d1f"
                                text-color="white"
                                >   
                                Código de referido:<b>{{ $UserManager.get().referral_code_to_share }}</b>
                                <v-icon right color="white">
                                    mdi-bullhorn-outline
                                </v-icon>
                            </v-chip>   
                           
                        </v-row>
                    </div>
                    <v-data-table
                        :headers="headers"
                        :items="referrals"
                        hide-default-footer
                        class="elevation-1"
                        :loading="loading"
                        loading-text="Cargando..."
                    >
                        <template v-slot:[`item.company`]="{ item }">
                            {{ item.business_name}} <br>
                            {{ item.tax_id}}
                        </template>
                        <template v-slot:[`item.contact`]="{ item }">
                            {{ item.contact_name}} <br>
                            <b>{{ item.contact_phone}}</b>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-col v-if="item.status_name">
                                <v-chip
                                    small
                                    class="black white--text"
                                >
                                    <b>{{ item.status_name }}</b>
                                </v-chip>
                                <p class="mt-2" v-if="item.status_comment">{{ item.status_comment }}</p>
                            </v-col>
                        </template>
                         <template v-slot:[`item.is_authorized`]="{ item }">
                            <v-chip
                                small
                                :class="`ma-2 ${item.is_authorized ===  null ? 'warning' : (item.is_authorized == true ? 'success' : 'error')} white--text`"
                            >
                                <b>{{item.is_authorized ===  null ? 'Pendiente' : (item.is_authorized == true ? 'Autorizado' : 'No autorizado') }}</b>
                            </v-chip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <CreateCustomerModal :visible="showCreateCustomerModal"/>
    </v-main>
</template>

<script>
import CustomerService from '@/services/customerService'
// import CreateCustomerModal from "@/components/CreateCustomerModal.vue"

export default {

    props: ["visible"],
    components: {
        // CreateCustomerModal
    },
    
    data() {
        return {
            referrals: [],
            headers: [
                {"text": "Empresa", "value": "company"}, 
                {"text": "Contacto", "value": "contact"},
                {"text": "Estado", "value": "status"},
                {"text": "Autorizado", "value": "is_authorized"}
            ],
            loading: false,
            showCreateCustomerModal: false
        }
    },

    async mounted () {
        await this.getReferralsBySupplier()
    },

    methods: {
        async getReferralsBySupplier() {
            this.loading = true

            const response = await CustomerService.getReferralsBySupplier()
            const status = response.status
            const result = await response.json()
            
            if (status != 200) {
                console.log("Ocurrio un error")
                return
            }

            this.referrals = result.data
            this.loading = false
        },
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.reset()
            this.$emit('close')
          }
        }
      }
    },
}
</script>
