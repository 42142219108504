<template>
  <div class="text-center">
  <DialogAlert :visible="dialogAlert" :message="dialogAlertContent" @handleCloseDialogAlert="handleCloseDialogAlert"/>
   <v-dialog
      v-model="show"
      max-width="500"
    >
     <v-card >
        <v-container>
          <v-row class="mb-4">
            <v-card-title class="mb-5 text-h4 font-weight-medium">
              {{ !account ? 'Agregar' : 'Editar' }} cuenta bancaria
            </v-card-title>
          </v-row>

          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
              <v-text-field
                v-model="form.bank_name"
                label="Nombre del banco"
                :rules="[v => !!v || 'El campo es requerido']"
                required
                outlined
              ></v-text-field>

               <v-select
                v-model="form.currency"
                :items="['pesos', 'usd']"
                :rules="[v => !!v || 'El campo es requerido']"
                label="Moneda"
                outlined
              ></v-select>

               <v-text-field
                v-model="form.account_number"
                label="Número de cuenta"
                :rules="[v => !!v || 'El campo es requerido']"
                required
                outlined
              ></v-text-field>

               <v-text-field
                v-model="form.account_key"
                label="CLABE"
                :rules="[v => !!v || 'El campo es requerido']"
                required
                outlined
              ></v-text-field>
              
                <v-text-field
                v-model="form.beneficiary_name"
                label="Nombre del beneficiario"
                :rules="[v => !!v || 'El campo es requerido']"
                required
                outlined
              ></v-text-field>

              <v-row>
                 <v-col cols="6">
                    <v-btn
                    outlined block
                    @click="show = false"
          
                  >
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                      @click="validate"
                      outlined block
                    >
                      Confirmar
                    </v-btn>
                 </v-col>
              </v-row>
          </v-form>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
            @click="validate"
            outlined block
          >
            Guardar
          </v-btn>
           <v-btn
            outlined block
            @click="show = false"
  
          >
            Cancelar
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SupplierBankAccountsService from "@/services/supplierBankAccountsService.js"
import DialogAlert from "@/components/DialogAlert"

export default {
  
    props: [
      "visible",
      "isCreateForm",
      "account"
    ],

    components: {
      DialogAlert
    },

    data() {
        return {
            form: {
                id: "",
                bank_name: "",
                account_number: "",
                account_key: "",
                currency: "",
                beneficiary_name: ""
            },
            valid: false,
            dialogAlert: false,
            dialogAlertContent: {
                title: "",
                success: true,
                text: ""
            },
        }
    },

    methods: {
      async handleSubmit () {

        let response = null

        if (this.isCreateForm) {
          response = await SupplierBankAccountsService.create(this.form)
        } else {
          response = await SupplierBankAccountsService.update(this.form)
        }

        const status = response.status

        if (status != 200) {
          this.dialogAlertContent.title = "Error"
          this.dialogAlertContent.success = false
          this.dialogAlertContent.text = "Ocurrio un error al " + (this.isCreateForm ? "agregar" : "actualizar")  + " la cuenta"
          this.dialogAlert = true
          return
        }

        this.dialogAlertContent.title = this.isCreateForm ? "Registrado" : "Actualizado"
        this.dialogAlertContent.success = true
        this.dialogAlertContent.text = "La cuenta fue " + (this.isCreateForm ? "agregada" : "actualizada")
        this.dialogAlert = true

        this.show = false
        this.reset()
        
        this.$emit('updateBusinessInformation')
      },

      async validate () {
          const isValid = this.$refs.form.validate()

          if (!isValid) {
              return
          }

          await this.handleSubmit()
      },

      reset() {
        this.form.id = null
        this.form.bank_name = ""
        this.form.currency = ""
        this.form.account_number = ""
        this.form.account_key = ""
        this.form.beneficiary_name = ""
        this.valid = false
      },

      handleCloseDialogAlert () {
          this.dialogAlert = false
          this.dialogAlertContent.title = ""
          this.dialogAlertContent.success = ""
          this.dialogAlertContent.text = ""
      },
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.reset()
            this.$emit('close')
          }
        }
      }
    },

    watch: {
      account(){ 
        if (this.isCreateForm == false) {
          this.form.id = this.account.id
          this.form.bank_name = this.account.bank_name
          this.form.account_number = this.account.account_number
          this.form.account_key = this.account.account_key
          this.form.currency = this.account.currency
          this.form.beneficiary_name = this.account.beneficiary_name
        }
      }
    }
}

</script>
