<template>
    <v-menu
        bottom
        left
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="#1d1d1f"
            style="color: #fff;"
            v-bind="attrs"
            v-on="on"
            small
            >
            Ver acciones
            </v-btn>
        </template>

        <v-list class="font-weight-bold">
            <v-list-item 
                v-if="showPayButton"
            >
                <v-list-item-title>
                    <v-btn 
                        block 
                        color="success"
                        rounded
                        @click="handleAction('pending')"
                    >{{ ($UserManager.getRole() == 'admin-customer' || $UserManager.getRole() == 'customer') ? 'Pagar' : 'Informar pago' }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item 
                v-if="showConfirmPaymentButton"
            >   
                <v-list-item-title>
                    <v-btn
                        color="success"
                        rounded
                        @click="handleAction('to_confirm')"
                    >
                        Confirmar
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item 
                v-if="showRejectPaymentButton"
            >
                <v-list-item-title>
                    <v-btn
                    color="error"
                    rounded
                    @click="handleAction('reject')"
                >
                    Rechazar
                </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item 
                link
                v-if="showRequestExtendTermButton"
                @click="handleAction('request_extend_term')"
            >
                <v-list-item-title>Solicitar extensión de plazo</v-list-item-title>
            </v-list-item>
            <v-list-item 
                link
                v-if="item.invoice_url"
                @click="handleAction('show_invoice')"
            >
                <v-list-item-title>Ver factura</v-list-item-title>
            </v-list-item>
            <v-list-item 
                link
                v-if="showProofFileButton"
                @click="handleAction('show_file')"
            >
                <v-list-item-title>Ver comprobante</v-list-item-title>
            </v-list-item>
            <v-list-item 
                link
                v-if="showExtenderTermButton"
                @click="handleAction('extend_term')"
            >
                <v-list-item-title>Extender plazo</v-list-item-title>
            </v-list-item>
            <v-list-item 
                link
                v-if="showReportNotPaymentButton"
                @click="handleAction('expired')"
            >
                <v-list-item-title>Informar impago</v-list-item-title>
            </v-list-item>
            <v-list-item 
                link
                v-if="showAttachInvoiceButton"
                @click="handleAction('attach_invoice')"
            >
                <v-list-item-title>Adjuntar factura</v-list-item-title>
            </v-list-item>
            <v-list-item 
                link
                v-if="showEditButton"
                @click="handleAction('edit_payment')"
            >
                <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
    export default {
        props: ["item", "page", "index"],

        data() {
            return {}
        },

        methods: {
            async handleAction(code) {
                this.$emit("handleAction", this.item, code);
            }
        },

        computed: {
            showPayButton() {
                return (
                        (this.index < 3 && this.page == 1) && 
                        this.item.status.name == 'pending' || 
                        this.item.status.name == 'rejected' || 
                        this.item.status.name == 'expired' || 
                        this.item.status.name == 'not_reported' || 
                        this.item.status.name == 'non_payment' ||
                        (this.item.status.name == 'paid_by_mangxo' && this.$UserManager.getMainRole() == 'customer')
                    ) 
                    || 
                    (this.$UserManager.getMainRole() == 'supplier' && this.item.status.name != "to_confirm")
            },

            showRequestExtendTermButton() {
                return  (
                        this.item.status.name == 'pending' 
                        && 
                        !this.item.pending_request_extend_term &&
                        this.$UserManager.getRole() == 'admin-customer')
            },

            showExtenderTermButton () {
                return  (    
                            this.item.status.name == 'pending' ||
                            this.item.status.name == 'expired' ||
                            this.item.status.name == 'non_payment'
                        ) && 
                        this.$UserManager.getRole() == 'admin-supplier'
            },

            showProofFileButton () {
                return  (
                            this.item.status.name == 'to_confirm' ||
                            this.item.status.name == 'paid' ||
                            this.item.status.name == 'paid_by_mangxo' ||
                            this.item.status.name == 'paid_to_mangxo' ||
                            this.item.status.name == 'payment_in_financing'
                        ) && 
                        (
                            this.$UserManager.getRole() == 'admin-supplier' ||
                            this.$UserManager.getRole() == 'admin-customer'
                        )  && 
                        this.item.proof_payment_url
            },

            showConfirmPaymentButton () {
                return (this.item.status.name == 'to_confirm' || this.item.status.name == 'not_reported') && (this.$UserManager.getRole() == 'admin-supplier') && !this.item.is_factoring
            },

            showRejectPaymentButton () {
                return this.item.status.name == 'to_confirm' && this.$UserManager.getRole() == 'admin-supplier' && !this.item.is_factoring
            },

            showReportNotPaymentButton () {
                return this.item.status.name == 'expired' && this.$UserManager.getRole() == 'admin-supplier'
            },

            showAttachInvoiceButton () {
                return (this.item.status.name == 'pending' && this.item.invoice_url == null && this.$route.name != 'dashboard-supplier') && this.$UserManager.getRole() == 'admin-supplier'
            },

            showEditButton () {
                return (this.item.status.name == 'pending' && this.$route.name != 'dashboard-supplier') && this.$UserManager.getRole() == 'admin-supplier'
            }
        }
    }
</script>