<template>
  <div class="text-center">
    <v-dialog
        v-model="show"
        max-width="400"
      >
      <v-card>
          <v-card-title class="mb-5 text-h4 font-weight-medium">Extender plazo</v-card-title>
          <v-card-text>
            
              <div style="font-size: 1.2em;">Cliente: {{ payment.customer.business_name }}</div>
              <div style="font-size: 1.2em;">Monto: ${{ payment.amount }}</div>
              <div style="font-size: 1.2em;">Plazo actual: {{ payment.term }}</div>
        
            <v-form>
              <v-row>
                <v-col
                  cols="12"
                  class="mt-4"
                >
                  <v-text-field
                    v-model="term"
                    label="Días"
                    required
                    type="number"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-col cols="12" v-if="message">
              <div class="red--text">{{message}}</div>
            </v-col>
            <div class="d-flex">
              <v-col cols="6">
                <v-btn
                  outlined
                  block
                  @click="show = false"
                >
                  Cerrar
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  outlined
                  block
                  class="black white--text"
                  @click="handleSubmit"
                >
                  Confirmar
                </v-btn>
              </v-col>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>

import PaymentService from "../services/paymentService"

export default {
    props: [
      "payment",
      "visible"
    ],

    data() {
        return {
          term: null,
          payment_id: null,
          message: ""
        }
    },

    methods: {
      async handleSubmit () {
        if(this.term == null || this.term == "") {
          this.message = "Debe ingresar la cantidad de días a extender"
          return
        }

        const response = await PaymentService.extendTerm(this.payment_id, this.term)
        const status = response.status
        const result = await response.json()
        if (status != 200) {
          this.message = result.msg
          return
        }
        
        this.$emit("showDialogAlert", result)
        this.$emit("refreshData")
        this.show = false
      },
      reset() {
        this.term = null
        this.payment_id = null
        this.message = ""
      },
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.reset()
            this.$emit('close')
          }
        }
      }
    },

    watch: {
      payment(item) {
        console.log(item)
        this.payment_id = item.id
      }
    }
}

</script>

<style>

@media (max-width: 998px) {
    
}

</style>