<template>
   <v-main>
        <v-row class="main-row" style="background: #fff;">
            <v-col 
                cols="2"
                class="bg-purple-darken-2 d-none d-md-flex"
                style="background: rgb(232 231 238); border-radius: 0em 2.5em 2.5em 0em;"
            >
                <status-component :currentStep="currentStep"/>
            </v-col>

            <v-col 
                cols="12"
                class="d-block d-md-none"
            >
                <v-img 
                    class="mb-5"
                    src="@/assets/logo_white.png" 
                    width="120"
                    style="margin: auto; margin-top: 1em;"
                />

                <StatusMobile :currentStep="currentStep"/>

            </v-col>
            
            <v-col
                md="10"
                cols="12"
                class="d-flex align-center"
                style="background:#fff;"
            >   
                <v-col 
                    cols="12"
                    md="8"
                    offset-md="2"
                >
                    <router-view 
                        @handleLoading="handleLoading"
                        @nextStep="nextStep"
                        @saveStep="saveStep"
                    ></router-view>
                </v-col>
            </v-col>
        </v-row>
        <loading-component v-if="loading"/>
   </v-main>
</template>

<script>
    import Status from "@/components/register/Status"
    import Loading from "@/components/register/Loading"
    import StatusMobile from "@/components/register/StatusMobile"
    import StatusCustomerService from "@/services/statusCustomerService"

    export default {
        components: {
            "status-component": Status,
            StatusMobile,
            "loading-component": Loading,
        },

        data() {
            return {
                loading: false,
                currentStep: 1,
            }
        },

        created() {
            this.currentStep = localStorage.getItem("currentStep")
        },

        updated () {
            this.currentStep = localStorage.getItem("currentStep")
        },

        methods: {
            handleLoading (value) {
                this.loading = value
            },

            async saveStep (step) {
                // this.$emit("handleLoading", true)

                await StatusCustomerService.create(step)

                // this.$emit("handleLoading", false)
            },

            nextStep (step) {
                this.$router.push({ path: '/register/step-' + step });
            }
        }
    }
</script>

<style>
    .btn-continue {
        background: #1d1d1f;
        color: #fff !important;
        border-color: #1d1d1f;
        text-transform: capitalize !important;
        padding: 1em !important;
    }

    .btn-continue-disabled {
        background-color: #ccc;
        color: #fff !important;
        border-color: #ccc !important;
    }

    .btn-back {
        background: #1d1d1f !important;
        color: #fff !important;
    }

    @media(min-width: 998px) {
        .main-row{
            height: 100vh;
        }
    }

</style>