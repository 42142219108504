<template>
    <v-container id="form-container">
       <v-row class="mt-6">
          <v-col
              lg="12"
              md="12"
              >      
            <v-card class="pa-3">
              <v-row class="pa-6 align-center">
                <h3>Dirección de entrega</h3>
              </v-row>
            </v-card>
          </v-col>
       </v-row>
       <v-row>
      <v-col cols="12">
        <RewardsComponent />
      </v-col>
       </v-row>
      <v-row class="mt-6">
          <v-col
              lg="12"
              md="12"
              >      
            <v-card class="pa-6">
              <v-form
                v-model="valid"
              >
                
                <v-text-field
                  v-model="address"
                  label="Dirección"
                  :rules="rules.address"
                  outlined
                ></v-text-field>
    
                <v-btn
                  :disabled="!valid"
                  class="mr-4 black white--text"
                  @click='$emit("handleConfirm")'
                >
                  CONTINUAR
                </v-btn>
                <a
                  style="font-size: 0.8em; color: #000 !important"
                  class="mr-4"
                  @click='$emit("handleSkip")'
                >
                  OMITIR
                </a>
              </v-form>
            </v-card>
          </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {

    props: ['value'], 

    data() {
        return {
            valid: false,
            rules: {
              address: [
                v => !!v || 'El campo es requerido',
                v => v.length <= 500 || 'Caracteres excedidos. Máximo: 500'
              ],
            }
        }
    },

    computed: {
        address: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value);
            }
        },
    }
}

</script>