<template>
<v-dialog
    v-model="show"
    max-width="600"
    >
    <v-card class="pa-6">
      <v-card-title class="mb-5 text-h4 font-weight-medium">Registrar cliente</v-card-title>
      <v-card-text>
        <v-form 
          ref="form"
          v-model="valid"
    
        >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.business_name"
              label="Nombre de la empresa"
              :rules="rules.required"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select 
                v-model="form.business_type"
                label="Figura jurídica"
                :items="[
                    { text: 'Persona física', value: 'person' },
                    { text: 'Persona moral', value: 'company' }
                ]"
                :rules="rules.businessTypeRequired"
                outlined
            ></v-select>
          </v-col>
                    
          <v-col cols="6">
            <v-text-field
              v-model="form.contact_name"
              label="Nombre de contacto"
              :rules="rules.required"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.contact_phone"
              label="Teléfono de contacto"
              :rules="rules.phone"
              maxlength="10"
              prefix="+52"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.contact_email"
              label="Correo de contacto"
              :rules="rules.email"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <vuetify-money
              v-model="form.credit_requested"
              label="Línea de crédito deseada"
              valueWhenIsEmpty=""
              v-bind:options="options"
              outlined
            />
          </v-col>
          <v-col cols="6">
            <v-btn @click="show = false" outlined block>
              Cerrar
            </v-btn>
          </v-col>
          <v-col>
              <v-btn
                class="black white--text custom-disabled-btn"
                @click="handleSubmit"
                :disabled="(!valid || inSubmit)"
                outlined
                block
              >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn @click="show = false" outlined>
          Cerrar
        </v-btn>
        <v-btn
          class="black white--text"
          @click="handleSubmit"
          :disabled="(!valid || inSubmit) || !valid"
          outlined
        >
          Registrar
        </v-btn> -->
      </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import options from '@/config/money'
import CustomerService from "@/services/customerService"

export default {
    props: [
      "visible"
    ],

    data() {
        return {
            form: {
              business_name: null,
              contact_name: null,
              contact_phone: null,
              contact_email: null,
              credit_requested: null,
              business_type: null,
              origin: "frontdesk"
            },
            inSubmit: false,
            valid: false,
            rules: {
              required: [ v => !!v || 'El campo es requerido'],
              email: [v => /.+@.+\..+/.test(v) || 'Correo no válido'],
              phone: [v => /^\d{10}$/.test(v) || 'El número debe incluir +52 y tener 10 dígitos.'],
              businessTypeRequired: [
                v => v !== '' || 'Debes seleccionar una figura jurídica'
              ]
            },
        }
    },

    methods: {
        async handleSubmit() {

          this.form.contact_phone = "52" + this.form.contact_phone

          const response = await CustomerService.createStep1(this.form)
          const status = response.status

          const data = {
            success: false,
            msg: "Ocurrio un error"
          }

          if (status == 200) {
            data.success = true
            data.msg = "Cliente registrado"
          }

          this.reset()
          this.$emit("close")
          this.$emit("showDialogAlert", data)
        },

        reset () {
          this.form.business_name = "",
          this.form.contact_name = "",
          this.form.business_type = "",
          this.form.contact_phone = "",
          this.form.contact_email = "",
          this.form.credit_requested = "",
          this.form.origin = "frontdesk"
        }
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close')
          }
        }
      },
      options: () => {
        return options
      }
    },
}

</script>

<style>
  .custom-disabled-btn.v-btn.v-btn--disabled {
    color: #fafafa !important; /* Cambia el color del texto a rojo cuando está deshabilitado */
    border-color: #fafafa !important; /* Opcional: cambia también el borde si es necesario */
    opacity: 1 !important; /* Evita que Vuetify aplique opacidad al botón deshabilitado */
    background-color: #ccc !important; /* Opcional: mantener fondo negro si es necesario */
}

</style>