<template>
    <div>
        <v-form 
            ref="form"
            v-model="valid"
        >
            <v-container
                style="height: 100%;"
                class="align-center"
            >
            <v-col cols="12" class="text-center">
                <div style="font-size: 2.5em; font-weight: 500;">¡Comencemos!</div>
                <p class="font-weight-bold">Completa tus datos</p>
            </v-col>
            <v-row>
                <v-col
                cols="12"
                md="12"
                >
                    <v-text-field
                        ref="business_name_input"
                        v-model="form.business_name"
                        label="Nombre de tu empresa"
                        :rules="rules.required"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="12"
                >
                    <v-text-field
                        v-model="form.contact_name"
                        label="Tu nombre"
                        :rules="rules.required"
                        outlined
                    ></v-text-field>
                </v-col>
                <v-col 
                cols="12" 
                md="6"
                >
                    <v-text-field
                        v-model="form.contact_phone"
                        label="Tu celular"
                        :rules="rules.phone"
                        outlined
                        autocomplete="off"
                        maxlength="10"
                    >
                        <template v-slot:prepend-inner>
                            <span style="margin-top: 3px; display: inline-block;">+52</span>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="6"
                >
                    <v-text-field
                        v-model="form.contact_email"
                        label="Tu correo"
                        :rules="rules.email"
                        outlined
                        hint="Te avisaremos a este correo acerca del estado de tu solicitud"
                        persistent-hint
                    ></v-text-field>
                    <span></span>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                    <v-select
                        v-model="form.business_type"
                        label="Figura jurídica"
                        :items="[
                            { text: 'Persona física', value: 'person' },
                            { text: 'Persona moral', value: 'company' }
                        ]"
                        :rules="rules.businessTypeRequired"
                        outlined
                    ></v-select>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                <vuetify-money
                    v-model="form.credit_requested"
                    label="Línea de crédito deseada"
                    hint="Te avisaremos a este correo acerca del estado de tu solicitud"
                    persistent-hint
                    v-bind:options="options"
                    outlined
                ></vuetify-money>
                </v-col>

                <v-col
                    cols="12"
                    md="12"
                >
                    <v-checkbox
                        v-model="form.optin_data_protection"
                        :rules="rules.requiredCheckbox"
                    >
                        <template v-slot:label>
                            <div>
                                Acepto
                                <a
                                    target="_blank"
                                    href="https://mangxo.com/policy"
                                    @click.stop
                                >
                                    política de privacidad y uso de datos
                                </a>
                            </div>
                        </template>
                    </v-checkbox>
                    <v-checkbox
                        v-model="form.optin_terms_and_conditions"
                        :rules="rules.requiredCheckbox"
                    >
                        <template v-slot:label>
                            <div>
                                Acepto
                                <a
                                    target="_blank"
                                    href="https://mangxo.com/terms-and-conditions"
                                    @click.stop
                                >
                                    términos y condiciones
                                </a>
                            </div>
                        </template>
                    </v-checkbox>
                </v-col>
                <ErrorMessage v-if="errorMessage" :errorMessage="errorMessage"/>
                <v-col
                cols="6"
                md="6"
                offset="3"
                >
                    <v-btn
                        block
                        elevation="2"
                        outlined
                        rounded
                        large
                        :class="!valid ? 'btn-continue-disabled' : 'btn-continue'"
                        @click="handleSubmit"
                        :disabled="!valid"
                    >
                        Continuar
                    </v-btn>
                </v-col>
            </v-row>
            </v-container>
        </v-form>
    </div>
</template>
<script>

    import RegisterService from "@/services/registerService"
    import ErrorMessage from "@/components/register/ErrorMessage"
    import options from '@/config/money'

    export default {
        name: "register-info",

        components: {
            ErrorMessage
        },

        data() {
            return {
                form: {
                    business_name: "",
                    contact_name: "",
                    contact_phone: "",
                    credit_requested: "",
                    business_type: "",
                    optin_data_protection: false,
                    optin_terms_and_conditions: false
                },

                valid: false,
                rules: {
                    required: [
                        v => !!v || 'El campo es requerido',
                    ],
                    requiredCheckbox: [
                        v => v === true || 'El campo es requerido',
                    ],
                    phone: [
                        v => !!v || 'El campo es requerido',
                        v => v.length === 10 || "El número debe tener al menos 10 digitos ",
                        v => /^[0-9]+$/.test(v) || "Solo se permiten números",
                    ],
                    email: [
                        v => !!v || 'El campo es requerido',
                        v =>  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) ||  "Debe ingresar un correo valido"
                    ],
                    businessTypeRequired: [
                        v => v !== '' || 'Debes seleccionar una figura jurídica'
                    ]
                },
                errorMessage: ""
            }
        },
        mounted(){
            this.$refs.business_name_input.focus()
        },

        methods: {
            async handleSubmit() {
                if (!this.form.business_type) {
                    this.errorMessage = "Debes seleccionar una figura jurídica";
                    return; // Detener la ejecución si no está seleccionado
                }
                // Guardar en `localStorage`
                localStorage.setItem('businessType', this.form.business_type);
                
                const formattedPhone = `52${this.form.contact_phone}`;
                this.form.contact_phone = formattedPhone;

                this.$emit("handleLoading", true)

                const response = await RegisterService.step1(this.form)
                const status = response.status
                const result = await response.json()

                this.$emit("handleLoading", false)

                switch (status) {
                    case 200:
                        localStorage.setItem("register_token", result.register_token)
                        this.$router.push({ path: '/register/files/company' });
                    break
                    case 422:
                        this.errorMessage = "Verifique los datos"
                    break
                    case 500:
                    case 403:
                        this.errorMessage = result.msg
                    break
                }
            }
        },

        computed: {
            options: () => {
                return options
            }
        }
    
    }
</script>
