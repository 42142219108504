<template>
<v-main>
    <v-container
    class="py-8 px-6"
    
    >
    <v-row class="mb-6 text-center" v-if="showWelcomeMessage">
      <v-col cols="12">
        <div class="text-h4">Te has registrado</div>
         <div class="text-h4">¡Bienvenido!</div>
      </v-col>
    </v-row>
     <v-row>
        <v-col
        lg="4"
        offset-lg="4"
        md="12"
        >
          <v-img
            max-height="150"
            max-width="250"
            src="@/assets/logo_new.png"
            class="mx-auto"
            ></v-img>

            <v-card class="pa-6 mt-6">
                <v-form
                  v-model="valid"
                  lazy-validation
                  @submit.prevent="submit"
                  ref="form"
                >
    
                  <v-text-field
                  label="Email"
                  outlined
                  dense
                  autocomplete="false"
                  class="mt-16"
                  v-model="user.email"
                  :rules="[rules.required]"
                  :error-messages="errorsMessages.email ? errorsMessages.email[0] : ''"
                  />
                  <v-text-field
                    label="Password"
                    outlined
                    dense
                    autocomplete="false"
                    type="password"
                    v-model="user.password"
                    :rules="[rules.required]"
                    :error-messages="errorsMessages.password ? errorsMessages.password[0] : ''"
                    />
              
                    <div class="red--text" v-if="errorMessage">{{ errorMessage }}</div>
                    <v-btn color="black" style="color: white;" class="mt-4" type="submit" :disabled="disabled" rounded block>Ingresar</v-btn>
                </v-form>
                <v-row class="mt-2">     
                  <v-col cols="12" class="text-center">
                      <router-link to="register/info" class="" style="color: #000;">¿No tiene cuenta? Registrarse</router-link>
                      <br>
                      <a href="https://mangxo.com/forgot-password" class="" style="color: #000;">¿Olvidaste tu contraseña?</a>
                    <br>
                  </v-col>    
                </v-row>     
            </v-card>
        </v-col>
     </v-row>
    </v-container>
</v-main>
</template>


<script>

import UserService from "../services/userService"

export default {
  data: () => ({
      user: {
        email: "",
        password: ""
      },
      rules: {
        required: v => !!v || 'El campo es requerido',
      },
      valid: false,
      errorsMessages: {},
      errorMessage: '',
      disabled: false,
      showWelcomeMessage: false
  }),

  created() {
        this.showWelcomeMessage = this.$route.params.showMsg
  },

  methods: {
    async submit() {
        const valid = this.$refs.form.validate()
        if (!valid) return

        this.disabled = true
        
        const response = await UserService.login(this.user)
        const status = response.status
        const result = await response.json()

        switch(status){
            case 200:
                if (result.token) {
                    localStorage.setItem('token', result.token)
                    const user = await this.getUser()

                    if (user) {
                      this.$UserManager.store(user)
                      
                      const redirect_to_checkout = localStorage.getItem("redirect_to_checkout")
                      if (redirect_to_checkout) {
                        localStorage.removeItem("redirect_to_checkout")
                        this.$router.back()
                        return
                      }

                      this.$router.push({path: "/dashboard" + "-" + this.$UserManager.getMainRole()})
                    }
                }
            break;
            case 422:
                this.valid = false
                this.errorsMessages = result.errors
            break;
            case 403:
            case 404:
            case 500:
                this.errorMessage = result.msg
            break;
        }

        this.disabled = false
    },

    async getUser() {
      const response = await UserService.getUser()
      const status = response.status
      const result = await response.json()

      if (status == 200) {
        return result
      }

      this.errorMessage = "Ocurrio un error"
      return null
    }
  }
}
</script>

<style scoped>
.v-main {
    background-size: cover;
    position: relative !important; 
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size:cover;
    background-attachment: fixed;
    background-position: center;
    /* background-image: linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('https://mangxo.com/img/bg-header.jpeg'); */
}

.v-card {
    color: #fff;
    box-shadow: none !important;
    background: none;
}
</style>