<template>
  <div class="text-center">
    <v-dialog
        v-model="show"
        max-width="400"
      >
      <v-card>
          <v-card-title class="mb-5 text-h4 font-weight-medium">Crear pago</v-card-title>
          <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation 
            >
                <v-col cols="12">
                    <div style="font-size: 1.2em;" class="mb-2 font-weight-medium">Cliente: {{ selected.business_name }}</div>
                    <div style="font-size: 1.2em;" class="mb-2">ID de reserva: {{ selected.id }}</div>
                    <div style="font-size: 1.2em;" class="mb-2">Monto de línea: {{ selected.amount | currency }}</div>
                    <div style="font-size: 1.2em;" class="mb-2"><b>Disponible: {{ selected.available | currency }}</b></div>
                </v-col>
        
               <v-col
                  cols="12"
                >
                  <vuetify-money
                    v-model="amount"
                    label="Monto"
                    type="number"
                    :rules="[v => !!v || 'El campo es requerido']"
                    v-bind:options="options"
                    outlined
                  ></vuetify-money>
                  <v-text-field
                    v-model="term"
                    label="Días"
                    type="number"
                    :rules="[v => !!v || 'El campo es requerido']"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="reference"
                    label="Referencia"
                    type="text"
                    outlined
                  ></v-text-field>
                </v-col>
                
                <div class="d-flex">
                  <v-col cols="6">
                    <v-btn
                      block
                      @click="close"
                      outlined
                    >
                      Cerrar
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                      <v-btn
                        block
                        class="black white--text"
                        @click="validate"
                        :disabled="loading"
                        outlined
                      >
                    {{ loading ? "Cargando..."  : "Crear" }}
                    </v-btn>
                  </v-col>
                </div>
            </v-form>
            <div v-if="message" class="red--text">{{message}}</div>
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import PaymentService from '../../services/paymentService'
import options from '@/config/money'

export default {
    props: [
      "visible",
      "selected"
    ],

    data() {
        return {
          amount: null,
          term: null,

          loading: false,
          message: null,
          valid: false,
        }
    },

    methods: {

      async validate () {
        const isValid = this.$refs.form.validate()

        if (!isValid) {
            return
        }

        await this.handleSubmit()
      },
      
      async handleSubmit () {
       
        this.loading = true

        const data = {
          amount: this.amount,
          term: this.term,
          reference: this.reference,
          customer_id: this.selected.customer_id,
          reserved_credit_line_id: this.selected.id
        }

        const response = await PaymentService.createOnReservedCreditLine(data)
        const result = await response.json()
        const status = response.status

        if (status == 200) {
          this.$emit("showMessage", {success: true, msg: result.msg})
          this.close()
        } else {
          this.message = result.msg
        }

        this.loading = false
      },

      close () {
        this.reset()
        this.$emit('close')
      },

      reset() {
        this.amount = null
        this.term = null
        this.customer_id = null
        this.message = null
      }
    },

    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.close()
          }
        }
      },
      options: () => {
        return options
      }
    },

    filters: {
        currency(value) {
            
            var formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
            
            return formatter.format(value);
        }
    }
}

</script>