<template>
    <v-dialog
        v-model="show"
        max-width="500"
        >
        <v-card class="pa-6">
          <v-card-title class="mb-5 text-h5 font-weight-medium">Registrar usuario comprador</v-card-title>
          <v-card-text>
            <p>El usuario que registres tendrá acceso a ver y realizar compras en Mangxo.</p>
            <v-form 
              ref="form"
              v-model="valid"
        
            >
    
              <v-text-field
                v-model="form.name"
                label="Nombre"
                :rules="rules.required"
                outlined
              ></v-text-field>
    
              <v-text-field
                v-model="form.email"
                label="Email"
                :rules="rules.email"
                outlined
              ></v-text-field>
              <div class="d-flex">
                <v-col cols="6">
                  <v-btn @click="show = false" block outlined>
                    Cerrar
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    v-if="!inSubmit"
                    block
                    class="custom-btn"
                    @click="handleSubmit"
                    :disabled="inSubmit || !valid"
                    outlined
                >
                    Guardar
                </v-btn>
                  <v-progress-circular
                      v-else
                      indeterminate
                      color="#1d1d1f"
                      style="margin-left: 10px;"
                  >
                  </v-progress-circular>
                </v-col>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
    </v-dialog>
    </template>
    
    <script>
    import CustomerService from "@/services/customerService"
    
    export default {
        props: [
          "visible"
        ],
    
        data() {
            return {
                form: {
                  name: "",
                  phone: ""
                },
                inSubmit: false,
                valid: false,
                rules: {
                  required: [ v => !!v || 'El campo es requerido'],
                  email: [v => /.+@.+\..+/.test(v) || 'Correo no válido']
                },
            }
        },
    
        methods: {
            async handleSubmit() {
                
                this.inSubmit = true

                const response = await CustomerService.createBuyerCustomer(this.form)
                const status = response.status

                const data = {
                    success: false,
                    msg: "Ocurrio un error"
                }

                if (status == 200) {
                    data.success = true
                    data.msg = "Usuario registrado"
                }

                this.inSubmit = false

                this.reset()
                this.$emit("close")
                this.$emit("showDialogAlert", data)
            },
    
            reset () {
              this.form.name = "",
              this.form.email = ""
            }
        },
    
        computed: {
          show: {
            get () {
              return this.visible
            },
            set (value) {
              if (!value) {
                this.$emit('close')
              }
            }
          },
        },
    }
    
    </script>

    <style>
      .custom-btn {
          background: #1d1d1f;
          border-color: #1d1d1f !important;  /* Borde negro cuando el botón está habilitado */
          color: #fff !important;         /* Texto negro si prefieres cambiarlo cuando está habilitado */
      }

      /* Estilo cuando el botón está deshabilitado */
      .custom-btn.v-btn.v-btn--disabled {
          background-color: #1d1d1f;
          color: #ccc !important;           /* Cambia el color del texto cuando el botón está deshabilitado */
          border-color: #1d1d1f !important;  /* Mantiene el borde negro incluso cuando está deshabilitado */
          opacity: 1 !important;           /* Elimina la opacidad predeterminada de Vuetify */
      }

    </style>