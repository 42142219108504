<template>
  <v-card 
    class="elevation-2 mb-6" 
    :class="{ 'border-project-default': !isExceededBudget, 'border-project-exceeded': isExceededBudget }"
  >
    <div style="padding: 1.5em;">
      <v-col cols="12">
        <v-row class="d-flex align-center">
            <h2 style="color: #1d1d1f">{{ project.name }}</h2>
            <v-spacer></v-spacer>
            <v-btn 
              v-if="project.id"
              x-small 
              style="color: #fff;"
              @click="$emit('editProject', project)"
              color="#1d1d1f"
            >
              Editar
              <v-icon 
                x-small
                class="ml-2"
              >mdi-pencil</v-icon>
            </v-btn>
        </v-row>
      </v-col>
      
      <div class="info-section">  
        <div class="info-row align-center d-flex" v-if="project.address">
          <v-icon small color="#1d1d1f">mdi-map-marker</v-icon>
          <span class="info-value">{{ project.address || '-' }}</span>
        </div>
        <v-col cols="12">
          <v-row class="mt-4" style="display: flex; justify-content: space-between;">
              <div class="text-start">
                <div class="font-weight-medium">Presupuesto</div>
                <div class="text-h6" style="color: #1d1d1f;"><b>{{ project.budget | currency }}</b></div>
              </div>
              <div class="text-end">
                <div 
                  class="font-weight-medium"
                >
                  Total consumido
                </div>
                <div 
                  class="text-h6" 
                  :style="{ color: isExceededBudget ? 'red' : '#1d1d1f' }"
                >
                  <b>{{ project.total_amount | currency }}</b>
                </div>
              </div>
          </v-row>
        </v-col>
      </div>
      <div class="row">
        <v-col cols="12" class="mt-3">
          <v-progress-linear
            :value="project.percentage_used"
            :color="!isExceededBudget ? '#1d1d1f' : 'red'"
            height="20"
            style="border-radius: 0.5em;"
          >
            <strong style="color: #fff !important;" v-if="!isExceededBudget">{{ project.percentage_used }}%</strong>
            <strong style="color: #fff !important;" v-else>Excedido en {{ (project.percentage_used - 100).toFixed(2) }}%</strong>
          </v-progress-linear>
        </v-col>
      </div>
      
    </div>
  </v-card>
</template>
  
<script>
  export default {
    name: "ProjectCard",
    props: {
      project: {
        type: Object,
        required: true
      }
    },

    methods: {
      formatAmount(value) {
        const amount = parseFloat(value);
        return isNaN(amount) ? "0.00" : amount.toFixed(2);
      },
    },

    filters: {
      currency(value) {
        const amount = parseFloat(value);
        return isNaN(amount) ? "$0.00" : new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN'
        }).format(amount);
      }
    },

    computed: {
      isExceededBudget () {
        return this.project.percentage_used >= 100;
      }
    }
  };
</script>
  

<style scoped>
  
  .v-card-title {
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .v-chip {
    border-radius: 20px;
    font-size: 1rem;
    padding: 8px;
  }
  
  .v-card-text {
    background-color: #f8f9fa; /* Fondo claro para contraste */
  }

  .border-project-default {
    border-right: 4px solid #1d1d1f;
  }

  .border-project-exceeded {
    border-right: 4px solid rgb(243, 94, 94);
  }

</style>
  