<template>
  <v-main>
    <v-container class="py-8 px-6">
      <v-row>
        <v-col lg="6" offset-lg="3" md="12" align="center">
          <v-row>
            <v-col cols="12">
                <v-col style="padding: 0 !important;">
                  <SuppliersCarousel v-if="$UserManager.getMainRole() == 'customer'"/>
                </v-col>
                <v-alert
                  v-if="response.token"
                  type="info"
           
                  color="#1d1d1f"
                >
                  <!-- <template v-slot:actions>
                    <v-btn icon @click="closeBenefits">
                      <v-icon class="" color="#732C9C">mdi-close</v-icon>
                    </v-btn>
                  </template> -->
                  Aumenta tu linéa de crédito <b>asignando</b> tus <b>compras</b> a <b>proyectos.</b>
                </v-alert>
              </v-col>
            </v-row>
          <v-row class="d-flex align-center mb-5">
            <v-col cols="6" v-if="response.token">
              <div class="text-h6  mt-1 text-left">Tu número de usuario: {{ $UserManager.getId() }}</div>

            </v-col><!-- Botón para asignar a un proyecto -->
            <v-col cols="6" v-if="response.token">
              <v-btn
                color="#1d1d1f"
                @click="openProjectAssignmentDialog"
                rounded
                outlined
                block
                small
              >
                Asignar a proyecto
              </v-btn>
            </v-col>

          </v-row>   
          <!-- Componente del diálogo para asignar proyecto -->
          <DialogProjectAssignment
            v-model="showProjectDialog"
            @confirm="handleProjectAssignment"
          />       
          <v-card>
            <div v-if="selectedProject" :key="selectedProject?.id" class="project-banner">
              Esta compra se asignará al proyecto: {{ selectedProject.name }}
            </div>

            <v-card-title class="justify-center">
              Código de compra
            </v-card-title>
            
            <div class="text-h3 mt-3 mb-4" v-if="response.token">
              {{ response.token }}
              <div style="font-size: 0.3em;" v-if="response.token && response.expire_in > 0">
                Expirará en: {{ minutes }}:{{ seconds <= 9 ? '0' + seconds : seconds }}
              </div>
            </div>

            <v-col cols="12" sm="6" md="6">
              <v-btn
                :color="copyButton.color"
                outlined
                @click="copyData"
                :disabled="!response.token"
                block
              >
                <v-icon color="light">mdi {{ copyButton.icon }}</v-icon>
                {{ copyButton.text }}
              </v-btn>
            </v-col>
            
            <v-col cols="12" sm="6" md="6">
              <v-btn
                color="#1d1d1f"
                style="color: #fff;"
                :loading="loading"
                :disabled="loading"
                @click="handleGenerateToken"
                block
                medium
              >
                Generar
              </v-btn>
            </v-col>     
          </v-card>
          <SharedToken />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

  
<script>

import CustomerTokenService from '../services/customerTokenService'
import SharedToken from '../components/SharedToken.vue'
import DialogProjectAssignment from '@/components/DialogProjectAssignment.vue';
import SuppliersCarousel from "@/components/SuppliersCarousel.vue"
  
  export default {
      components: {
      DialogProjectAssignment,
      SharedToken,
      SuppliersCarousel
    },
  
    data() {
        return {
          response: {},
          timer: null,
          minutes: 0,
          seconds: 0,
          loading: false, // Controlador de loading
          copyButton: {
          text: 'Copiar código',
          color: 'dark',
          icon: 'mdi-clipboard',
          },
          showProjectDialog: false,
          selectedProject: JSON.parse(localStorage.getItem("selectedProject")) || null,
      };
    },

    mounted() {
        this.fetchToken();
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.timer);
        // Continúa con la navegación
        next();
    },
    watch: {
    selectedProject: {
      immediate: true, // Se ejecuta al inicio
      handler(newProject) {
        if (newProject) {
          console.log("Proyecto asignado:", newProject);
          localStorage.setItem("selectedProject", JSON.stringify(newProject));
        } else {
          console.log("Proyecto eliminado.");
          localStorage.removeItem("selectedProject");
        }
      }
    }
  },
  
    methods: {
      async fetchToken() {
          try {
              const response = await CustomerTokenService.getToken();
              const result = await response.json();
      
              if (result.token) {
                  this.response = result;
                  this.startTimer(result.expire_in); // Iniciar el contador con el tiempo restante
                  this.updateLocalToken(result.token)
              }
          } catch (error) {
              console.error('Error al obtener el token:', error);
          }
      },

      updateLocalToken (token) {
        const currentToken = localStorage.getItem("purchase_token")

        if (currentToken && currentToken != token) {
          localStorage.setItem("purchase_token", token)
          localStorage.removeItem("selectedProject");
          this.selectedProject = null;
        } else {
          localStorage.setItem("purchase_token", token)
        }
      },

      async handleGenerateToken() {
        this.loading = true; // Inicia el indicador de carga
        try {
          const response = await CustomerTokenService.generate();
          const result = await response.json();

          if (result.token) {
            this.response = result;
            this.startTimer(result.expire_in); // Reinicia el temporizador
            this.resetCopyButton();

            // Borrar el proyecto asignado
            this.selectedProject = null;
            localStorage.removeItem("selectedProject"); // Eliminar del localStorage
            console.log("Se ha generado un nuevo token y eliminado el proyecto asignado.");
          }
        } catch (error) {
          console.error("Error al generar el token:", error);
        } finally {
          this.loading = false; // Detiene el indicador de carga
        }
      },
  
      startTimer(expireIn) {
        clearInterval(this.timer); // Detener cualquier temporizador previo

        this.minutes = Math.floor(expireIn / 60);
        this.seconds = expireIn % 60;

        this.timer = setInterval(async () => {
            if (this.seconds === 0) {
                if (this.minutes === 0) {
                    clearInterval(this.timer); // Detener el temporizador si llega a 0
                    this.response.token = null; // Ocultar el token
                    await this.handleGenerateToken() 
                } else {
                    this.minutes -= 1;
                    this.seconds = 59;
                }
            } else {
                this.seconds -= 1;
            }
        }, 1000);
      },
  
      copyData() {
          const data = this.response.token;
          navigator.clipboard.writeText(data);
  
          this.copyButton = {
          text: 'Código copiado',
          color: 'success',
          icon: 'mdi-clipboard-check',
          };
      },
  
      resetCopyButton() {
          this.copyButton = {
              text: 'Copiar código',
              color: 'dark',
              icon: 'mdi-clipboard',
          };
      },

      openProjectAssignmentDialog() {
        this.showProjectDialog = true;
      },

      closeBenefits() {
        this.showProjectBenefits = false;
      },

      handleProjectAssignment(project) {
        console.log("Proyecto asignado exitosamente:", project);
        this.selectedProject = project;
        localStorage.setItem("selectedProject", JSON.stringify(project)); // Guardar en localStorage
      },
      updateSelectedProject(project) {
        // Forzar reactividad para `selectedProject`
        this.selectedProject = null;
        this.$nextTick(() => {
          this.selectedProject = project;
        });
      }
  },
};
</script>
<style scoped>
  .custom-banner {
    background-color: #732C9C !important;
    color: white !important;
    margin-bottom: 2.3rem;
  }
  i.v-icon.notranslate.mdi.mdi-information.theme--light{
    color: white !important;
  }

  .project-banner {
    background-color: #1d1d1f;
    color: #fff;
    border-radius: 20px;
    padding: 10px 20px;
    text-align: center;
    margin-bottom: 15px;
    font-weight: 600;
    /* border: 1px solid #732C9C !important; */
  }
</style>
  