<template>
    <v-layout>
        <v-app-bar
        dark
        class="d-flex d-sm-none"
        fluid
        
        >
       
            <v-img
                :width="50"
                src="@/assets/icon_white_new.png"
                 @click.stop="drawer = !drawer"
            ></v-img>
        
        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
        >
        <v-list
            nav
            dense
        >
            <v-list-item-group
            v-model="group"
            active-class="text--accent-4"
            >
             <template v-for="link in links">
                <v-list-item
                    :key="link.name"
                    text
                    :to="link.link"
                    v-if="!link.hasOwnProperty('roles') || $UserManager.getRole() && link.roles.includes($UserManager.getRole())"
                    :id="link.id ? link.id : ''"
                >
                    <b>{{ link.name }}</b>
                    <v-icon
                            medium  
                            class="ml-2"
                            v-if="link.icon" 
                        >
                        {{ link.icon }}
                    </v-icon>
                </v-list-item>
            </template>
                <v-list-item
                    text
                    href="https://mangxo.com/help-center/suppliers"
                    target="_blank"
                    v-if="$UserManager.getMainRole() == 'supplier'"
                >
                    <b>Centro de ayuda</b>
                <v-icon
                    medium   
                    >
                    mdi-help-circle-outline
                </v-icon>
                </v-list-item>  
                <v-list-item @click="logout">
                    <b>Salir</b>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        </v-navigation-drawer>
        <v-app-bar
            app
            class="d-none d-sm-flex"
         >
           <v-img
            :width="50"
            src="@/assets/icon_white_new.png"
        ></v-img>

            <v-container class="py-0 fill-height " fluid>
                <template v-for="link in links">
                    <v-btn
                        :key="link.name"
                        text
                        :to="link.link"
                        v-if="!link.hasOwnProperty('roles') || $UserManager.getRole() && link.roles.includes($UserManager.getRole())"
                        :id="link.id ? link.id : ''"
                    >
                        {{ link.name }}
                         <v-icon
                            medium  
                            class="ml-2"
                            v-if="link.icon" 
                        >
                        {{ link.icon }}
                        </v-icon>
                    </v-btn>
                </template>         
                <!-- <v-btn
                    text
                    href="https://mangxo.com/help-center/suppliers"
                    target="_blank"
                    v-if="$UserManager.getMainRole() == 'supplier'"
                >
                    <v-icon
                        medium   
                    >
                    mdi-help-circle-outline
                    </v-icon>
                    Centro de ayuda
                </v-btn>   -->
                <v-btn
                    
                    text
                    @click="logout"
                    >
                        Salir
                </v-btn>
            </v-container>
        </v-app-bar>
    </v-layout>
</template>
<script>
export default {
    data: () => ({
        drawer: false,
        links: [
            { name: 'Inicio', link: '/dashboard-customer', roles: ["admin-customer", "customer"]},
            { name: 'Inicio', link: '/dashboard-supplier', roles: ["admin-supplier"]},
            //{ name: 'Clientes', link: '/', roles: ['admin-supplier', 'vendor-supplier']},
            { name: 'Nueva venta', link: '/front-desk', roles: ['admin-supplier', 'vendor-supplier']},
            { name: 'Ventas', link: '/payments', roles: ['admin-supplier','vendor-supplier']},
            { name: 'Clientes', link: '/referrals', roles: ['admin-supplier']},     
            { name: 'Compras', link: '/payments', roles: ['admin-customer', 'customer']},
            { name: 'Mis proveedores', link: '/suppliers', roles: ['admin-customer', 'customer']},
            { name: 'Nueva compra', link: '/token', roles: ['admin-customer', 'customer']},
            { name: 'Perfil', link: '/profile', roles: ['admin-supplier']},
            { name: '¿Donde comprar?', link: '/where-buy', roles: ['admin-customer', 'customer']},
            // { name: '¿Como usar Mangxo?', link: '/how-to-use', roles: ['admin-customer', "customer"]},
            { name: 'Proyectos', link: '/projects', roles: ['admin-customer', "customer"]},
            // { name: 'Recompensas', link: '/rewards', roles: ['admin-customer'], id: "rewards-link", icon: "mdi-cake-variant"},
        ],
    }),

    methods: {
        logout() {
            this.$UserManager.destroy()
            this.$router.push({ name: 'login'})
        }
    }
}
</script>

<style scoped>
.v-app-bar {
    background: #1d1d1f !important;
}

.v-app-bar .v-btn {
   color: #fff !important;
}

#rewards-link {
    /* background: rgb(254, 199, 15); */
    color: #1d1d1f !important;
}

#rewards-link .v-icon {
    color: #1d1d1f !important; 
}

</style>
