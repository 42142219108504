<template>
    <v-dialog v-model="isVisible" max-width="450px" transition="dialog-bottom-transition">
        <v-card>
            <v-card-title class="headline d-flex justify-space-between align-center">
                <span>Reporte de clientes</span>
                <v-btn icon @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="pt-4">
                <v-radio-group v-model="reportOption" column>
                    <v-radio label="Reporte del mes actual" value="current_month" prepend-icon="mdi-calendar-month" />
                    <v-radio label="Reporte del mes anterior" value="previous_month" prepend-icon="mdi-calendar-month" />
                </v-radio-group>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="d-flex justify-end">
                <v-btn color="#1d1d1f" @click="closeDialog" outlined>Cancelar</v-btn>
                <v-btn color="#1d1d1f" :loading="loading" :disabled="loading || !reportOption" @click="generateReport" outlined>
                    <v-icon left>mdi-file-chart</v-icon> Generar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import PaymentService from '../services/paymentService';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isVisible: this.visible,
            reportOption: null,
            loading: false,
        };
    },
    watch: {
        visible(val) {
            this.isVisible = val;
        },
        isVisible(val) {
            if (!val) this.$emit('close');
        },
    },
    methods: {
        closeDialog() {
            this.isVisible = false;
            this.reportOption = null;
            this.loading = false;
        },

        async generateReport() {
            if (!this.reportOption) {
                this.$emit('showDialogAlert', { success: false, msg: 'Seleccione una opción de reporte.' });
                return;
            }

            this.loading = true;

            try {
                const response = await PaymentService.reportPayments(this.reportOption);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'payments_report.xlsx';
                a.click();
                window.URL.revokeObjectURL(url);

                this.$emit('showDialogAlert', { success: true, msg: 'Reporte generado con éxito.' });
                this.closeDialog();
            } catch (error) {
                console.error('Error al generar el reporte:', error);
                this.$emit('showDialogAlert', { success: false, msg: error.message });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style scoped>
    .headline {
        font-weight: bold;
        color: #4a4a4a;
    }
    .v-btn {
        margin-left: 8px;
    }
    .v-card-actions {
        padding: 16px;
    }
</style>